import { swapObject } from 'utils/object';
import { USER_ROLES } from './users';
import { findSectionName, findSectionValue } from 'utils/permission';
import PermissionGroup from 'utils/permissionGroup';

export const ADMIN_USER_ID = '1';

export const PERMISSION_ACTIONS = {
  create: 'CREATE',
  read: 'READ',
  update: 'UPDATE',
  delete: 'DELETE',
  viewDashboard: 'VIEW_DASHBOARD',
  viewTranslatorRequest: 'VIEW_TRANSLATOR_REQUEST',
  removeTranslatorRequest: 'REMOVE_TRANSLATOR_REQUEST',
  viewAnalytics: 'VIEW_ANALYTICS',
  blockDoctor: 'BLOCK_DOCTOR',
  disableDoctor: 'DISABLE_DOCTOR',
  appointmentViewPayment: 'APPOINTMENT_VIEW_PAYMENT',
  updatePayment: 'UPDATE_PAYMENT',
  changeStatus: 'CHANGE_STATUS',
  changeDate: 'CHANGE_DATE',
  updateState: 'UPDATE_STATE',
  generateUrl: 'GENERATE_URL',
  addReport: 'ADD_REPORT',
  viewReport: 'VIEW_REPORT',
  addNote: 'ADD_NOTE',
  viewNote: 'VIEW_NOTE',
  joinCall: 'JOIN_CALL'
};

const DEFAULT_PERMISSION_ACTIONS = [
  PERMISSION_ACTIONS.create,
  PERMISSION_ACTIONS.update,
  PERMISSION_ACTIONS.delete
];

export const PERMISSION_ROUTES = {
  super: 'super',
  dashboard: 'dashboard',
  doctors: 'doctors',
  patients: 'patients',
  plan: 'plan',
  translators: 'translators',
  translatorPlans: 'translator_plans',
  appointments: 'appointments',
  appointmentNote: 'appointment_note',
  appointmentReport: 'appointment_report',
  patientRequests: 'patient_requests',
  medicalTreatment: 'medical_treatment',
  specialties: 'specialties',
  blog: 'blog',
  sessions: 'sessions',
  sessionsPaid: 'sessions_paid',
  reviews: 'reviews',
  notification: 'notification',
  organization: 'organization',
  roles: 'roles',
  calendar: 'calendar',
  doctorProfile: 'doctor_profile',
  profile: 'profile',
  dicom: 'dicom',
  callReviews: 'call_reviews',
  staticContent: 'static_content',
  reports: 'reports',
  ai: 'ai',
  crmPatient: 'crm_patient',
  translation: 'translation',
  services: 'services',
  faq: 'faq',
  stories: 'stories',
  subscription: 'subscription',
  partnership: 'partnership',
  partner: 'partner'
};

export const SECTIONS_ALL = {
  [PERMISSION_ROUTES.super]: 0,
  [PERMISSION_ROUTES.dashboard]: 1,
  [PERMISSION_ROUTES.doctors]: 2,
  [PERMISSION_ROUTES.patients]: 3,
  [PERMISSION_ROUTES.plan]: 4,
  [PERMISSION_ROUTES.translators]: 5,
  [PERMISSION_ROUTES.translatorPlans]: 6,
  [PERMISSION_ROUTES.appointments]: 7,
  [PERMISSION_ROUTES.appointmentNote]: 8,
  [PERMISSION_ROUTES.appointmentReport]: 9,
  [PERMISSION_ROUTES.patientRequests]: 10,
  [PERMISSION_ROUTES.specialties]: 11,
  [PERMISSION_ROUTES.blog]: 12,
  [PERMISSION_ROUTES.sessions]: 13,
  [PERMISSION_ROUTES.sessionsPaid]: 14,
  [PERMISSION_ROUTES.reviews]: 15,
  [PERMISSION_ROUTES.notification]: 16,
  [PERMISSION_ROUTES.organization]: 17,
  [PERMISSION_ROUTES.roles]: 18,
  [PERMISSION_ROUTES.calendar]: 19,
  [PERMISSION_ROUTES.doctorProfile]: 20,
  [PERMISSION_ROUTES.dicom]: 21,
  [PERMISSION_ROUTES.callReviews]: 22,
  [PERMISSION_ROUTES.staticContent]: 23,
  [PERMISSION_ROUTES.reports]: 24,
  [PERMISSION_ROUTES.ai]: 25,
  [PERMISSION_ROUTES.translation]: 26,
  [PERMISSION_ROUTES.crmPatient]: 27,
  [PERMISSION_ROUTES.medicalTreatment]: 28,
  [PERMISSION_ROUTES.services]: 29,
  [PERMISSION_ROUTES.faq]: 30,
  [PERMISSION_ROUTES.stories]: 31,
  [PERMISSION_ROUTES.partnership]: 32,
  [PERMISSION_ROUTES.subscription]: 33,
  [PERMISSION_ROUTES.partner]: 34
};

export const SECTIONS_ALL_KEYS = swapObject(SECTIONS_ALL);

export const SECTIONS = {
  [USER_ROLES.admin]: SECTIONS_ALL,
  [USER_ROLES.doctor]: findSectionName([
    PERMISSION_ROUTES.dashboard,
    PERMISSION_ROUTES.calendar,
    PERMISSION_ROUTES.patients,
    PERMISSION_ROUTES.appointments,
    PERMISSION_ROUTES.appointmentNote,
    PERMISSION_ROUTES.appointmentReport,
    PERMISSION_ROUTES.doctorProfile,
    PERMISSION_ROUTES.dicom
  ]),
  [USER_ROLES.translator]: findSectionName([
    PERMISSION_ROUTES.calendar,
    PERMISSION_ROUTES.appointments
  ]),
  [USER_ROLES.patient]: {}
};

export const PERMISSIONS_GLOBAL = {
  [SECTIONS_ALL[PERMISSION_ROUTES.dashboard]]: new PermissionGroup([
    PERMISSION_ACTIONS.viewTranslatorRequest,
    PERMISSION_ACTIONS.removeTranslatorRequest,
    PERMISSION_ACTIONS.viewAnalytics
  ]),
  [SECTIONS_ALL[PERMISSION_ROUTES.doctors]]: new PermissionGroup([
    ...DEFAULT_PERMISSION_ACTIONS,
    PERMISSION_ACTIONS.blockDoctor,
    PERMISSION_ACTIONS.disableDoctor
  ]),
  [SECTIONS_ALL[PERMISSION_ROUTES.plan]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.patients]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.notification]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.ai]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.organization]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.reviews]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.roles]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.translators]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.translatorPlans]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.appointments]]: new PermissionGroup([
    ...DEFAULT_PERMISSION_ACTIONS,
    PERMISSION_ACTIONS.appointmentViewPayment,
    PERMISSION_ACTIONS.updatePayment,
    PERMISSION_ACTIONS.changeStatus,
    PERMISSION_ACTIONS.changeDate,
    PERMISSION_ACTIONS.updateState,
    PERMISSION_ACTIONS.joinCall
  ]),
  [SECTIONS_ALL[PERMISSION_ROUTES.appointmentNote]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.appointmentReport]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.patientRequests]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.specialties]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.blog]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.sessions]]: new PermissionGroup([
    ...DEFAULT_PERMISSION_ACTIONS,
    PERMISSION_ACTIONS.generateUrl
  ]),
  [SECTIONS_ALL[PERMISSION_ROUTES.sessionsPaid]]: new PermissionGroup([
    ...DEFAULT_PERMISSION_ACTIONS,
    PERMISSION_ACTIONS.generateUrl
  ]),
  [SECTIONS_ALL[PERMISSION_ROUTES.doctorProfile]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.dicom]]: new PermissionGroup(),
  [SECTIONS_ALL[PERMISSION_ROUTES.calendar]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.callReviews]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.staticContent]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.reports]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.translation]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.crmPatient]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.medicalTreatment]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.services]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.faq]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.stories]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.partnership]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.subscription]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  ),
  [SECTIONS_ALL[PERMISSION_ROUTES.partner]]: new PermissionGroup(
    DEFAULT_PERMISSION_ACTIONS
  )
};

export const PERMISSIONS: Record<
  string,
  Record<number, Record<string, number>>
> = {
  [USER_ROLES.admin]: {
    [SECTIONS_ALL[PERMISSION_ROUTES.dashboard]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.dashboard]],
    [SECTIONS_ALL[PERMISSION_ROUTES.doctors]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.doctors]],
    [SECTIONS_ALL[PERMISSION_ROUTES.plan]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.plan]],
    [SECTIONS_ALL[PERMISSION_ROUTES.patients]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.patients]],
    [SECTIONS_ALL[PERMISSION_ROUTES.notification]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.notification]],
    [SECTIONS_ALL[PERMISSION_ROUTES.ai]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.ai]],
    [SECTIONS_ALL[PERMISSION_ROUTES.organization]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.organization]],
    [SECTIONS_ALL[PERMISSION_ROUTES.reviews]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.reviews]],
    [SECTIONS_ALL[PERMISSION_ROUTES.roles]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.roles]],
    [SECTIONS_ALL[PERMISSION_ROUTES.translators]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.translators]],
    [SECTIONS_ALL[PERMISSION_ROUTES.translatorPlans]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.translatorPlans]],
    [SECTIONS_ALL[PERMISSION_ROUTES.appointments]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.appointments]],
    [SECTIONS_ALL[PERMISSION_ROUTES.appointmentNote]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.appointmentNote]],
    [SECTIONS_ALL[PERMISSION_ROUTES.appointmentReport]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.appointmentReport]],
    [SECTIONS_ALL[PERMISSION_ROUTES.patientRequests]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.patientRequests]],
    [SECTIONS_ALL[PERMISSION_ROUTES.specialties]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.specialties]],
    [SECTIONS_ALL[PERMISSION_ROUTES.blog]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.blog]],
    [SECTIONS_ALL[PERMISSION_ROUTES.doctorProfile]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.doctorProfile]],
    [SECTIONS_ALL[PERMISSION_ROUTES.sessions]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.sessions]],
    [SECTIONS_ALL[PERMISSION_ROUTES.sessionsPaid]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.sessionsPaid]],
    [SECTIONS_ALL[PERMISSION_ROUTES.callReviews]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.callReviews]],
    [SECTIONS_ALL[PERMISSION_ROUTES.staticContent]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.staticContent]],
    [SECTIONS_ALL[PERMISSION_ROUTES.reports]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.reports]],
    [SECTIONS_ALL[PERMISSION_ROUTES.translation]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.translation]],
    [SECTIONS_ALL[PERMISSION_ROUTES.crmPatient]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.crmPatient]],
    [SECTIONS_ALL[PERMISSION_ROUTES.medicalTreatment]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.medicalTreatment]],
    [SECTIONS_ALL[PERMISSION_ROUTES.services]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.services]],
    [SECTIONS_ALL[PERMISSION_ROUTES.faq]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.faq]],
    [SECTIONS_ALL[PERMISSION_ROUTES.stories]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.stories]],
    [SECTIONS_ALL[PERMISSION_ROUTES.partnership]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.partnership]],
    [SECTIONS_ALL[PERMISSION_ROUTES.subscription]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.subscription]],
    [SECTIONS_ALL[PERMISSION_ROUTES.partner]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.partner]]
  },
  [USER_ROLES.doctor]: {
    [SECTIONS_ALL[PERMISSION_ROUTES.calendar]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.calendar]],
    [SECTIONS_ALL[PERMISSION_ROUTES.patients]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.patients]],
    [SECTIONS_ALL[PERMISSION_ROUTES.appointmentNote]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.appointmentNote]],
    [SECTIONS_ALL[PERMISSION_ROUTES.appointmentReport]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.appointmentReport]],
    [SECTIONS_ALL[PERMISSION_ROUTES.appointments]]: findSectionValue(
      [
        PERMISSION_ACTIONS.read,
        PERMISSION_ACTIONS.create,
        PERMISSION_ACTIONS.update,
        PERMISSION_ACTIONS.delete,
        PERMISSION_ACTIONS.changeStatus
      ],
      SECTIONS_ALL[PERMISSION_ROUTES.appointments]
    ),
    [SECTIONS_ALL[PERMISSION_ROUTES.doctorProfile]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.doctorProfile]],
    [SECTIONS_ALL[PERMISSION_ROUTES.dicom]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.dicom]]
  },
  [USER_ROLES.translator]: {
    [SECTIONS_ALL[PERMISSION_ROUTES.calendar]]:
      PERMISSIONS_GLOBAL[SECTIONS_ALL[PERMISSION_ROUTES.calendar]],
    [SECTIONS_ALL[PERMISSION_ROUTES.appointments]]: findSectionValue(
      [PERMISSION_ACTIONS.read, PERMISSION_ACTIONS.joinCall],
      SECTIONS_ALL[PERMISSION_ROUTES.appointments]
    )
  }
};
