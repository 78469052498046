import { FieldTypes, FormValues } from './_types';

export const DEFAULT_VALUES: FormValues = {
  treatmentPlanning: true,
  treatmentPlanningValue: 0,
  documentTranslation: true,
  documentTranslationValue: 0,
  visaAssistance: true,
  visaAssistanceValue: 0,
  airportTransfer: true,
  airportTransferValue: 0,
  interpreterServices: true,
  interpreterServicesValuesUp2hours: 0,
  interpreterServicesValuesFrom2to4hours: 0,
  interpreterServicesValuesFrom4to6hours: 0,
  interpreterServicesValuesFullDay: 0,
  fullClinicSupport: true,
  fullClinicSupportValue: 0,
  vat: true,
  vatValue: 0
};

export const FIELDS_DATA = [
  [
    {
      name: 'treatmentPlanning',
      label: 'Treatment planning',
      disabled: true,
      type: FieldTypes.switch
    },
    {
      name: 'treatmentPlanningValue',
      label: 'Treatment planning price (€)',
      type: FieldTypes.field
    }
  ],
  [
    {
      name: 'documentTranslation',
      label: 'Document translation',
      type: FieldTypes.switch
    },
    {
      name: 'documentTranslationValue',
      label: 'Document translation price (€)',
      type: FieldTypes.field
    }
  ],
  [
    {
      name: 'visaAssistance',
      label: 'Visa assistance',
      type: FieldTypes.switch
    },
    {
      name: 'visaAssistanceValue',
      label: 'Visa assistance price (€)',
      type: FieldTypes.field
    }
  ],
  [
    {
      name: 'airportTransfer',
      label: 'Airport transfer',
      type: FieldTypes.switch
    },
    {
      name: 'airportTransferValue',
      label: 'Airport transfer price (€)',
      type: FieldTypes.field
    }
  ],
  [
    {
      name: 'interpreterServices',
      label: 'Interpreter services',
      type: FieldTypes.switch
    },
    {
      name: 'interpreterServicesValuesUp2hours',
      label: 'Interpreter services up 2 hours price (€)',
      type: FieldTypes.field
    },
    {
      name: 'interpreterServicesValuesFrom2to4hours',
      label: 'Interpreter services 2-4 hours price (€)',
      type: FieldTypes.field
    },
    {
      name: 'interpreterServicesValuesFrom4to6hours',
      label: 'Interpreter services 4-6 hours price (€)',
      type: FieldTypes.field
    },
    {
      name: 'interpreterServicesValuesFullDay',
      label: 'Interpreter services full day price (€)',
      type: FieldTypes.field
    }
  ],
  [
    {
      name: 'fullClinicSupport',
      label: 'Full clinic support',
      type: FieldTypes.switch
    },
    {
      name: 'fullClinicSupportValue',
      label: 'Full clinic support price (€)',
      type: FieldTypes.field
    }
  ],
  [
    { name: 'vat', label: 'VAT', type: FieldTypes.switch },
    { name: 'vatValue', label: 'VAT value (%)', type: FieldTypes.field }
  ]
];
