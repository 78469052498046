import { ReactNode } from 'react';
import {
  AppstoreOutlined,
  CommentOutlined,
  TranslationOutlined,
  VideoCameraOutlined,
  StarOutlined,
  UnorderedListOutlined,
  LockOutlined,
  TeamOutlined,
  BellOutlined,
  LineChartOutlined,
  WalletOutlined,
  IdcardOutlined,
  ToolOutlined,
  ReconciliationOutlined,
  MedicineBoxOutlined,
  HistoryOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';

// pages
import Dashboard from 'pages/Dashboard';
import DoctorProfile from 'pages/DoctorProfile';
import BlogNews from 'pages/BlogNews';
import BlogNewsDetails from 'pages/BlogNewsDetails';
import TranslationPlans from 'pages/TranslationPlans';
import SessionsFree from 'pages/Sessions/Free';
import SessionPaid from 'pages/Sessions/Paid';
import DoctorReviews from 'pages/Reviews/DoctorReviews';
import CallReviews from 'pages/Reviews/CallReviews';
import Appointments from 'pages/Appointments';
import AppointmentsDetails from 'pages/AppointmentsDetails';
import PatientRequests from 'pages/PatientRequests';
import PatientRequestsDetails from 'pages/PatientRequestsDetails';
import Organizations from 'pages/Organizations';
import OrganizationSettings from 'pages/OrganizationSettings';
import {
  Admins,
  Roles,
  Doctors,
  Patients,
  RoleDetails,
  Translators
} from 'pages/UsersAndAccess';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from './permissions';
import Translations from 'pages/Translations';
import { ActivityLogs, MeetingsLogs } from 'pages/Logs';
import Notifications from 'pages/Notifications';
import DicomViewer from 'pages/Sessions/DicomViewer';
import CreateAppointment from 'pages/CreateAppointment';
import {
  InvoiceTemplates,
  InvoiceTemplateDetails,
  FinancialReports,
  FinancialAnalytics
} from 'pages/Financial';
import TranslatorRequests from 'pages/TranslatorRequests';
import {
  DoctorHowItWorks,
  DoctorHowItWorksContent,
  FAQ,
  HowItWorks,
  HowItWorksContent,
  StaticPage
} from 'pages/StaticPages';
import { CONFIG_PAGES } from './configs';
import AiHealth from 'pages/AiHealth';
import StaticElements from 'pages/StaticElements';
import MedicalTreatment from 'pages/MedicalTreatment';
import MedicalTreatmentDetails from 'pages/MedicalTreatmentDetails';
import Services from 'pages/Services';
import ServicesDetails from 'pages/ServicesDetails';
import Specialties from 'pages/Specialties';
import OrganizationResult from 'components/shared/OrganizationResult';
import Stories from 'pages/Stories';
import StoriesDetails from 'pages/StoriesDetails';
import SubscriptionPlans from 'pages/Subscriptions/SubscriptionPlans';
import SubscriptionFeatures from 'pages/Subscriptions/SubscriptionFeatures';
import SubscriptionPlansDetails from 'pages/Subscriptions/SubscriptionPlans/SubscriptionPlansDetails';
import SubscriptionFeaturesDetails from 'pages/Subscriptions/SubscriptionFeatures/SubscriptionFeaturesDetails';
import Partners from 'pages/Partners';
import PartnerDetails from 'pages/PartnerDetails';
import PartnerAnalytic from 'pages/PartnerAnalytic';

type RouteItem = {
  key: string | string[];
  action?: string;
  path: string;
  label?: string;
  icon?: ReactNode;
  element?: ReactNode;
  renderParent?: boolean;
  hideInSidebar?: boolean;
};

export type RouteObject = RouteItem & {
  children?: RouteItem[];
};

export const MAIN_ROUTES: RouteObject[] = [
  {
    path: '/',
    element: <Dashboard />,
    icon: <AppstoreOutlined style={{ fontSize: 18 }} />,
    label: 'dashboard',
    key: PERMISSION_ROUTES.dashboard
  },
  {
    path: '/specialties',
    label: 'specialties',
    key: PERMISSION_ROUTES.specialties,
    icon: <MedicineBoxOutlined style={{ fontSize: 18 }} />,
    element: <Specialties />
  },
  {
    path: '/translation-plans',
    label: 'translation_plans',
    key: PERMISSION_ROUTES.translatorPlans,
    icon: <TranslationOutlined style={{ fontSize: 18 }} />,
    element: <TranslationPlans />
  },
  {
    path: '/organizations',
    label: 'organizations',
    key: PERMISSION_ROUTES.organization,
    icon: <TeamOutlined style={{ fontSize: 18 }} />,
    element: <Organizations />
  },
  {
    path: '/organizations/settings/:id/:page',
    key: PERMISSION_ROUTES.organization,
    action: PERMISSION_ACTIONS.update,
    element: <OrganizationSettings />,
    hideInSidebar: true
  },
  {
    path: '/blog-news',
    label: 'blog_news',
    key: PERMISSION_ROUTES.blog,
    icon: <CommentOutlined style={{ fontSize: 18 }} />,
    renderParent: true,
    children: [
      {
        path: '',
        key: PERMISSION_ROUTES.blog,
        element: <BlogNews />
      },
      {
        path: 'details',
        label: 'blog_news',
        key: PERMISSION_ROUTES.blog,
        action: PERMISSION_ACTIONS.create,
        element: <BlogNewsDetails />
      },
      {
        path: 'details/:id',
        label: 'blog_news',
        key: PERMISSION_ROUTES.blog,
        action: PERMISSION_ACTIONS.update,
        element: <BlogNewsDetails />
      }
    ]
  },
  {
    path: '/services',
    label: 'services',
    key: PERMISSION_ROUTES.services,
    icon: <ReconciliationOutlined style={{ fontSize: 18 }} />,
    renderParent: true,
    children: [
      {
        path: '',
        key: PERMISSION_ROUTES.services,
        element: <Services />
      },
      {
        path: 'details',
        label: 'services',
        key: PERMISSION_ROUTES.services,
        action: PERMISSION_ACTIONS.create,
        element: <ServicesDetails />
      },
      {
        path: 'details/:id',
        label: 'services',
        key: PERMISSION_ROUTES.services,
        action: PERMISSION_ACTIONS.update,
        element: <ServicesDetails />
      }
    ]
  },
  {
    path: '/stories',
    label: 'stories',
    key: PERMISSION_ROUTES.stories,
    icon: <HistoryOutlined style={{ fontSize: 18 }} />,
    renderParent: true,
    children: [
      {
        path: '',
        key: PERMISSION_ROUTES.stories,
        element: <Stories />
      },
      {
        path: 'details',
        label: 'stories',
        key: PERMISSION_ROUTES.stories,
        action: PERMISSION_ACTIONS.create,
        element: <StoriesDetails />
      },
      {
        path: 'details/:id',
        label: 'stories',
        key: PERMISSION_ROUTES.stories,
        action: PERMISSION_ACTIONS.update,
        element: <StoriesDetails />
      }
    ]
  },
  {
    path: '/notifications',
    label: 'notifications',
    key: PERMISSION_ROUTES.notification,
    icon: <BellOutlined style={{ fontSize: 18 }} />,
    renderParent: true,
    children: [
      {
        path: '',
        key: PERMISSION_ROUTES.notification,
        element: <Notifications />
      }
    ]
  },
  {
    path: '/ai-health',
    label: 'ai-health',
    key: PERMISSION_ROUTES.ai,
    icon: <IdcardOutlined style={{ fontSize: 18 }} />,
    renderParent: true,
    children: [
      {
        path: '',
        key: PERMISSION_ROUTES.ai,
        element: <AiHealth />
      }
    ]
  },
  {
    path: '/subscriptions',
    label: 'subscriptions',
    key: PERMISSION_ROUTES.super,
    icon: <LineChartOutlined style={{ fontSize: 18 }} />,
    children: [
      {
        path: 'plans',
        label: 'subscription_plans',
        key: PERMISSION_ROUTES.super,
        element: <SubscriptionPlans />
      },
      {
        path: 'plans/:id',
        label: 'subscription_plans',
        key: PERMISSION_ROUTES.super,
        element: <SubscriptionPlansDetails />,
        hideInSidebar: true
      },
      {
        path: 'plans/details',
        label: 'subscription_plans',
        key: PERMISSION_ROUTES.super,
        element: <SubscriptionPlansDetails />,
        hideInSidebar: true
      },
      {
        path: 'features',
        label: 'subscription_features',
        key: PERMISSION_ROUTES.super,
        element: <SubscriptionFeatures />
      },
      {
        path: 'features/:id',
        label: 'subscription_features',
        key: PERMISSION_ROUTES.super,
        element: <SubscriptionFeaturesDetails />,
        hideInSidebar: true
      },
      {
        path: 'features/details',
        label: 'subscription_features',
        key: PERMISSION_ROUTES.super,
        element: <SubscriptionFeaturesDetails />,
        hideInSidebar: true
      }
    ]
  },
  {
    path: '/partner',
    label: 'partner',
    key: PERMISSION_ROUTES.partner,
    icon: <UserSwitchOutlined style={{ fontSize: 18 }} />,
    children: [
      {
        path: '',
        label: 'all_partners',
        key: PERMISSION_ROUTES.partner,
        element: <Partners />
      },
      {
        path: 'analytic',
        label: '',
        hideInSidebar: true,
        key: PERMISSION_ROUTES.partner,
        element: <Partners />
      },
      {
        path: 'details',
        label: 'create_partner',
        key: PERMISSION_ROUTES.partner,
        action: PERMISSION_ACTIONS.create,
        element: <PartnerDetails />
      },
      {
        path: 'details/:id',
        label: 'partners',
        key: PERMISSION_ROUTES.partner,
        hideInSidebar: true,
        action: PERMISSION_ACTIONS.update,
        element: <PartnerDetails />
      },
      {
        path: 'analytic/:id',
        label: 'partners',
        key: PERMISSION_ROUTES.partner,
        hideInSidebar: true,
        element: <PartnerAnalytic />
      }
    ]
  },
  {
    path: '/financial',
    label: 'financial',
    key: [PERMISSION_ROUTES.super, PERMISSION_ROUTES.reports],
    icon: <WalletOutlined style={{ fontSize: 18 }} />,
    children: [
      {
        path: 'templates',
        label: 'templates',
        key: PERMISSION_ROUTES.super,
        element: <InvoiceTemplates />
      },
      {
        path: 'templates/create',
        key: PERMISSION_ROUTES.super,
        hideInSidebar: true,
        element: <InvoiceTemplateDetails />
      },
      {
        path: 'templates/:id',
        key: PERMISSION_ROUTES.super,
        hideInSidebar: true,
        element: <InvoiceTemplateDetails />
      },
      {
        path: 'reports',
        label: 'reports',
        key: PERMISSION_ROUTES.reports,
        element: <FinancialReports />
      },
      {
        path: 'analytics',
        label: 'analytics',
        key: PERMISSION_ROUTES.reports,
        element: <FinancialAnalytics />
      }
    ]
  },
  {
    path: '/appointments',
    label: 'appointments',
    key: [PERMISSION_ROUTES.appointments, PERMISSION_ROUTES.patientRequests],
    icon: <UnorderedListOutlined style={{ fontSize: 18 }} />,
    children: [
      {
        path: '',
        label: 'appointments',
        key: PERMISSION_ROUTES.appointments,
        element: <Appointments />
      },
      {
        path: ':id',
        key: PERMISSION_ROUTES.appointments,
        hideInSidebar: true,
        element: <AppointmentsDetails />
      },
      {
        path: 'create',
        key: PERMISSION_ROUTES.appointments,
        action: PERMISSION_ACTIONS.create,
        hideInSidebar: true,
        element: <CreateAppointment />
      },
      {
        path: 'patient-requests',
        label: 'patient_requests',
        key: PERMISSION_ROUTES.patientRequests,
        element: <PatientRequests />
      },
      {
        path: 'patient-requests/:id',
        label: 'patient_requests',
        key: PERMISSION_ROUTES.patientRequests,
        element: <PatientRequestsDetails />,
        hideInSidebar: true
      },
      {
        path: 'medical-treatment',
        label: 'medical_treatment',
        key: PERMISSION_ROUTES.medicalTreatment,
        element: <MedicalTreatment />
      },
      {
        path: 'medical-treatment/:id',
        label: 'medical_treatment',
        key: PERMISSION_ROUTES.medicalTreatment,
        element: <MedicalTreatmentDetails />,
        hideInSidebar: true
      },
      {
        path: 'translator-requests',
        label: 'translator_requests',
        key: PERMISSION_ROUTES.appointments,
        element: <TranslatorRequests />
      }
    ]
  },
  {
    path: '/logs',
    label: 'logs',
    key: PERMISSION_ROUTES.super,
    icon: <LineChartOutlined style={{ fontSize: 18 }} />,
    children: [
      {
        path: 'activity',
        label: 'activity',
        key: PERMISSION_ROUTES.super,
        element: <ActivityLogs />
      },
      {
        path: 'meetings',
        label: 'meetings',
        key: PERMISSION_ROUTES.super,
        element: <MeetingsLogs />
      }
    ]
  },
  {
    path: '/cms',
    label: 'cms',
    key: [PERMISSION_ROUTES.translation, PERMISSION_ROUTES.staticContent],
    icon: <ToolOutlined style={{ fontSize: 18 }} />,
    children: [
      {
        path: 'translations',
        label: 'translations',
        key: PERMISSION_ROUTES.translation,
        element: <Translations />
      },
      {
        path: 'static-elements',
        label: 'static_elements',
        key: PERMISSION_ROUTES.staticContent,
        element: <StaticElements />
      }
    ]
  },
  {
    path: '/sessions',
    icon: <VideoCameraOutlined style={{ fontSize: 18 }} />,
    label: 'sessions',
    key: [PERMISSION_ROUTES.sessions, PERMISSION_ROUTES.sessionsPaid],
    children: [
      {
        path: 'free',
        key: PERMISSION_ROUTES.sessions,
        label: 'free',
        element: <SessionsFree />
      },
      {
        path: 'paid',
        key: PERMISSION_ROUTES.sessionsPaid,
        label: 'paid',
        element: <SessionPaid />
      },
      {
        path: 'dicom',
        key: PERMISSION_ROUTES.super,
        label: 'dicom',
        element: <DicomViewer />
      }
    ]
  },
  {
    path: '/reviews',
    label: 'reviews',
    key: [PERMISSION_ROUTES.reviews, PERMISSION_ROUTES.callReviews],
    icon: <StarOutlined style={{ fontSize: 18 }} />,
    children: [
      {
        path: 'doctor',
        key: PERMISSION_ROUTES.reviews,
        label: 'doctor_reviews',
        element: <DoctorReviews />
      },
      {
        path: 'call',
        key: PERMISSION_ROUTES.callReviews,
        label: 'call_reviews',
        element: <CallReviews />
      }
    ]
  },
  {
    path: '/access',
    label: 'users_and_access',
    key: [
      PERMISSION_ROUTES.roles,
      PERMISSION_ROUTES.super,
      PERMISSION_ROUTES.doctors,
      PERMISSION_ROUTES.patients,
      PERMISSION_ROUTES.translators
    ],
    icon: <LockOutlined style={{ fontSize: 18 }} />,
    children: [
      {
        path: 'roles',
        key: PERMISSION_ROUTES.roles,
        label: 'roles',
        element: <Roles />
      },
      {
        path: 'roles/details/:id?',
        key: PERMISSION_ROUTES.roles,
        hideInSidebar: true,
        element: <RoleDetails />
      },
      {
        path: 'admins',
        key: PERMISSION_ROUTES.super,
        label: 'admins',
        element: <Admins />
      },
      {
        path: 'doctors',
        key: PERMISSION_ROUTES.doctors,
        label: 'doctors',
        element: <Doctors />
      },
      {
        path: 'doctors/:id/:page',
        key: PERMISSION_ROUTES.doctors,
        hideInSidebar: true,
        element: <DoctorProfile />
      },
      {
        path: 'patients',
        key: PERMISSION_ROUTES.patients,
        label: 'patients',
        element: <Patients />
      },
      {
        path: 'translators',
        key: PERMISSION_ROUTES.translators,
        label: 'translators',
        element: <Translators />
      }
    ]
  },
  {
    path: '/static-pages',
    label: 'static-pages',
    key: PERMISSION_ROUTES.staticContent,
    icon: <UnorderedListOutlined style={{ fontSize: 18 }} />,
    children: [
      {
        path: 'privacy',
        key: PERMISSION_ROUTES.staticContent,
        label: 'privacy',
        element: (
          <OrganizationResult>
            <StaticPage type={CONFIG_PAGES.POLICY} />
          </OrganizationResult>
        )
      },
      {
        path: 'terms',
        key: PERMISSION_ROUTES.staticContent,
        label: 'terms',
        element: (
          <OrganizationResult>
            <StaticPage type={CONFIG_PAGES.TERMS} />
          </OrganizationResult>
        )
      },
      {
        path: 'faq',
        label: 'faq',
        key: PERMISSION_ROUTES.faq,
        element: (
          <OrganizationResult>
            <FAQ />
          </OrganizationResult>
        )
      },
      {
        path: 'legal-notice',
        label: 'legal_notice',
        key: PERMISSION_ROUTES.staticContent,
        element: (
          <OrganizationResult>
            <StaticPage type={CONFIG_PAGES.LEGAL} />
          </OrganizationResult>
        )
      },
      {
        path: 'how-it-works',
        label: 'how_it_works',
        key: PERMISSION_ROUTES.staticContent,
        element: <HowItWorks />
      },
      {
        path: 'how-it-works/details/:id',
        key: PERMISSION_ROUTES.staticContent,
        action: PERMISSION_ACTIONS.update,
        hideInSidebar: true,
        element: <HowItWorksContent />
      },
      {
        path: 'doctor-how-it-works',
        label: 'doctor_how_it_works',
        key: PERMISSION_ROUTES.staticContent,
        element: <DoctorHowItWorks />
      },
      {
        path: 'doctor-how-it-works/:id',
        key: PERMISSION_ROUTES.staticContent,
        action: PERMISSION_ACTIONS.update,
        hideInSidebar: true,
        element: <DoctorHowItWorksContent />
      }
    ]
  }
];
