import { Button, Flex, QRCode, Typography } from 'antd';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';

import { StyledQrView } from '../_styled';

const { Text } = Typography;

interface IProps {
  color: string;
}

function triggerDownload(url: string, fileName: string) {
  const anchor = document.createElement('a');
  anchor.download = fileName;
  anchor.href = url;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

const QrView: FC<IProps> = ({ color }) => {
  const { t } = useTranslation();
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const handleDownloadSvgQRCode = () => {
    const qrCodeSvg = qrCodeRef.current?.querySelector<SVGElement>('svg');

    if (!qrCodeSvg) {
      console.error('QR Code SVG element not found.');

      return;
    }

    const serializedSvg = new XMLSerializer().serializeToString(qrCodeSvg);
    const svgBlob = new Blob([serializedSvg], {
      type: 'image/svg+xml;charset=utf-8'
    });

    const svgUrl = URL.createObjectURL(svgBlob);

    triggerDownload(svgUrl, 'QRCode.svg');
  };

  return (
    <StyledQrView>
      <Flex vertical gap={40} align="center">
        <Text style={{ fontSize: 20, fontWeight: 700 }}>
          {t('partners.qr_view')}
        </Text>
        <div ref={qrCodeRef}>
          <QRCode size={210} color={color} type="svg" value="-" />
        </div>
        <Button
          icon={<DownloadOutlined />}
          style={{ width: 210 }}
          onClick={handleDownloadSvgQRCode}
        >
          {t('partners.download_qr')}
        </Button>
      </Flex>
    </StyledQrView>
  );
};

export default QrView;
