import {
  App,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  FormProps,
  Image,
  Input,
  Row,
  Segmented,
  Select,
  Typography,
  Upload
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useFileUpload } from 'hooks';
import {
  DURATION_TYPE,
  DURATION_TYPE_DATA,
  UNIT_OPTIONS_VALUES
} from 'constants/subscriptions';
import { LANGUAGES } from 'constants/languages';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN
} from 'gql/subscription/mutations';
import {
  GET_SUBSCRIPTION_FEATURE_ITEMS,
  GET_SUBSCRIPTION_PLAN_BY_ID,
  GET_SUBSCRIPTION_PLANS
} from 'gql/subscription/queries';

import { PageWrapper } from 'components/ui';
import { PATTERNS } from 'utils/validation';
import { getFileUrl } from 'utils/file';
import { getInputDir } from 'utils/helpers';
import { showErrorMessage } from 'utils/showErrorMessage';
import { useSubscriptionFeatures } from '../hooks';

import {
  GetFeatureItemsQuery,
  GetSubscriptionByIdQuery
} from '@/generated/graphql';

type OptionType = {
  price: number;
  // most_popular: boolean;
  duration_type: number;
  apple_product_id: string;
  google_product_id: string;
  google_duration_id: string;
  stripe_product_id: string;
};

type FormType = {
  icon: File[];
  combine: string[];
  is_free: boolean;
  option_1: OptionType;
  option_2: OptionType;
};

const { Paragraph, Text } = Typography;

const SubscriptionPlansDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { modal } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const client = useApolloClient();
  const [imageUrl, setImageUrl] = useState<null | string>(null);
  const [duration, setDuration] = useState(DURATION_TYPE.month);
  const { upload } = useFileUpload({
    progress: true
  });

  const { data } = useQuery<GetFeatureItemsQuery>(
    GET_SUBSCRIPTION_FEATURE_ITEMS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: 100
          }
        }
      }
    }
  );

  const featureItems = data?.getFeatureItems?.data.results || [];

  const features = useSubscriptionFeatures();

  const { loading } = useQuery<GetSubscriptionByIdQuery>(
    GET_SUBSCRIPTION_PLAN_BY_ID,
    {
      fetchPolicy: 'no-cache',
      skip: !id || !featureItems.length,
      variables: {
        id
      },
      onCompleted(res) {
        const planItem = res.getSubscriptionById?.data;

        if (!planItem) {
          return;
        }

        const optionValues = planItem.options?.reduce((acc, item) => {
          if (item && item.duration_type) {
            const { duration_type, price, source } = item;

            acc[`option_${duration_type}`] = {
              price,
              apple_product_id: source?.apple_product_id || '',
              google_product_id: source?.google_product_id || '',
              google_duration_id: source?.google_duration_id || '',
              stripe_product_id: source?.stripe_product_id || ''
            };
          }

          return acc;
        }, {} as Record<string, Record<string, boolean | number | null | undefined>>);

        setImageUrl(planItem.icon || '');

        const combine = planItem.features?.map(planItemFeature => {
          const planFeature = featureItems.find(
            feature => feature?.subscription_feature_id === planItemFeature?.id
          );

          return planFeature?.id;
        });

        form.setFieldsValue({
          icon: planItem.icon
            ? [{ url: getFileUrl(planItem.icon), name: 'icon' }]
            : [],
          name: planItem.name,
          combine,
          ...optionValues
        });
      }
    }
  );

  const [createPlanItem, { loading: createLoading }] = useMutation(
    CREATE_SUBSCRIPTION_PLAN,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_SUBSCRIPTION_PLANS] });
        modal.success({
          title: t('messages.success'),
          content: t('subscription.create_plan_success')
        });
        navigate('/subscriptions/plans');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const [updatePlanItem, { loading: updateLoading }] = useMutation(
    UPDATE_SUBSCRIPTION_PLAN,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_SUBSCRIPTION_PLANS] });
        modal.success({
          title: t('messages.success'),
          content: t('subscription.update_plan_success')
        });
        navigate('/subscriptions/plans');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onDurationChange = (newDuration: number) => {
    setDuration(newDuration);
  };

  const onFinish = (values: FormType) => {
    const { option_1, option_2, ...rest } = values;

    const formattedOption = [
      {
        price: Number(option_1.price),
        most_popular: false,
        duration_type: DURATION_TYPE.month,
        source: {
          apple_product_id: option_1.apple_product_id,
          google_product_id: option_1.google_product_id,
          google_duration_id: option_1.google_duration_id,
          stripe_product_id: option_1.stripe_product_id
        }
      },
      {
        price: Number(option_2.price),
        most_popular: false,
        duration_type: DURATION_TYPE.year,
        source: {
          apple_product_id: option_2.apple_product_id,
          google_product_id: option_2.google_product_id,
          google_duration_id: option_2.google_duration_id,
          stripe_product_id: option_2.stripe_product_id
        }
      }
    ];

    const input = {
      ...rest,
      is_free: false,
      icon: imageUrl,
      combine: rest.combine.map((item: string) => +item),
      options: formattedOption
    };

    if (id) {
      return updatePlanItem({ variables: { input, id } });
    }

    return createPlanItem({ variables: { input } });
  };

  const onFinishFailed: FormProps['onFinishFailed'] = e => {
    const firstErrorName = e.errorFields?.[0]?.name?.[0];

    const errorDuration =
      typeof firstErrorName === 'string' && firstErrorName?.includes('option_')
        ? +firstErrorName.replace('option_', '')
        : -1;

    if (errorDuration > -1) {
      setDuration(errorDuration);
    }
  };

  return (
    <PageWrapper
      back
      loading={loading}
      title={t(`subscription.${id ? 'update' : 'create'}_plan`)}
      color="white"
      extra={[
        <Button
          key="submit-button"
          disabled={createLoading || updateLoading}
          loading={createLoading || updateLoading}
          type="primary"
          size="large"
          style={{ width: 150 }}
          onClick={() => form.submit()}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <Flex justify="space-between">
          <Form.Item
            name="icon"
            label={t('subscription.add_icon')}
            valuePropName="fileList"
            rules={[{ required: true }]}
            getValueFromEvent={e => {
              if (e && e.fileList) {
                return e.fileList;
              }

              return imageUrl ? [{ url: imageUrl, name: 'icon' }] : [];
            }}
          >
            <Upload
              maxCount={1}
              showUploadList={false}
              customRequest={e => {
                upload(e.file as File, {
                  onUploadProgress: e.onProgress
                })
                  .then(e => {
                    e.onSuccess;
                    setImageUrl(e);
                  })
                  .catch(e.onError);
              }}
              name="icon"
              listType="picture-card"
            >
              {imageUrl ? (
                <Image
                  src={getFileUrl(imageUrl)}
                  preview={false}
                  height={100}
                  width={100}
                  style={{ objectFit: 'contain' }}
                />
              ) : (
                <Flex vertical align="center" gap={8}>
                  <PlusOutlined style={{ color: '#2552F3' }} />
                  <Paragraph style={{ margin: 0, color: '#2552F3' }}>
                    {t('subscription.upload')}
                  </Paragraph>
                </Flex>
              )}
            </Upload>
          </Form.Item>
        </Flex>
        <Row gutter={[16, 16]}>
          {LANGUAGES.map(item => (
            <Col span={12} key={item.key}>
              <Form.Item
                name={[`name`, item.locale]}
                label={`Plan name in ${item.label}`}
                rules={[{ required: true }]}
              >
                <Input
                  size="large"
                  dir={getInputDir(item.locale)}
                  placeholder="Name"
                />
              </Form.Item>
            </Col>
          ))}
          <Col span={24}>
            <Form.Item
              name="combine"
              label={t('subscription.select_features')}
              rules={[{ required: true }]}
            >
              <Select
                size="large"
                mode="multiple"
                allowClear
                placeholder={t('subscription.select_features')}
                options={featureItems?.map(item => {
                  const feature = features?.find(
                    f => f.id === item.subscription_feature_id
                  );

                  return {
                    value: item.id,
                    label: (
                      <Text>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: feature?.name?.replace('<br />', '') || ''
                          }}
                        />
                        {' / '}
                        <Text style={{ fontWeight: 600 }}>
                          {t(
                            `subscription.${
                              UNIT_OPTIONS_VALUES[
                                item.unit as keyof typeof UNIT_OPTIONS_VALUES
                              ]
                            }`
                          )}
                        </Text>
                      </Text>
                    )
                  };
                })}
              >
                {DURATION_TYPE_DATA.map(item => (
                  <Select.Option
                    key={`unit-option-${item.label}`}
                    value={item.value}
                  >
                    {t(`subscription.${item.label}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider>{t('subscription.options')}</Divider>
        <Row>
          <Col span={24}>
            <Segmented
              options={DURATION_TYPE_DATA.filter(
                item => item.value !== DURATION_TYPE.free
              ).map(item => ({
                ...item,
                label: t(`subscription.${item.label}`)
              }))}
              block
              size="large"
              value={duration}
              onChange={e => onDurationChange(e as number)}
            />
          </Col>
          <Col span={24}>
            {DURATION_TYPE_DATA.filter(
              item => item.value !== DURATION_TYPE.free
            ).map(item => {
              return (
                <div
                  style={{
                    display: item.value === duration ? 'block' : 'none'
                  }}
                  key={`form-${item.value}`}
                >
                  <Row
                    style={{ paddingTop: 16 }}
                    align="middle"
                    gutter={[16, 0]}
                  >
                    <Col span={24}>
                      <Form.Item
                        name={[`option_${item.value}`, 'price']}
                        label={t('subscription.price')}
                        rules={[
                          { required: true },
                          { pattern: PATTERNS.price }
                        ]}
                      >
                        <Input
                          size="large"
                          maxLength={255}
                          placeholder="0.00"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={[`option_${item.value}`, 'apple_product_id']}
                        label="Apple product ID"
                        rules={[{ required: true }]}
                      >
                        <Input size="large" placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[`option_${item.value}`, 'google_product_id']}
                        label="Google product ID"
                        rules={[{ required: true }]}
                      >
                        <Input size="large" placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[`option_${item.value}`, 'google_duration_id']}
                        label="Google duration ID"
                        rules={[{ required: true }]}
                      >
                        <Input size="large" placeholder="" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={[`option_${item.value}`, 'stripe_product_id']}
                        label="Stripe product ID"
                        rules={[{ required: true }]}
                      >
                        <Input size="large" placeholder="" />
                      </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                      <Form.Item
                        name={[`option_${item.value}`, 'most_popular']}
                        style={{ margin: 0 }}
                      >
                        <Flex gap={4} align="center">
                          <Text>{t('subscription.most_popular')}</Text>
                          <Switch />
                        </Flex>
                      </Form.Item>
                    </Col> */}
                  </Row>
                </div>
              );
            })}
          </Col>
        </Row>
      </Form>
    </PageWrapper>
  );
};

export default SubscriptionPlansDetails;
