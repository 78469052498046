import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
  Select,
  Input,
  Row,
  Col,
  Segmented,
  App,
  Flex,
  FormProps
} from 'antd';
import {
  DURATION_TYPE,
  DURATION_TYPE_DATA,
  LIMIT_TYPE_DATA,
  UNIT_OPTIONS,
  UNIT_OPTIONS_DATA
} from 'constants/subscriptions';
import { useState } from 'react';
import { LANGUAGES } from 'constants/languages';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_SUBSCRIPTION_FEATURE_ITEM,
  UPDATE_SUBSCRIPTION_FEATURE_ITEM
} from 'gql/subscription/mutations';
import {
  GET_SUBSCRIPTION_FEATURE_ITEM_BY_ID,
  GET_SUBSCRIPTION_FEATURE_ITEMS
} from 'gql/subscription/queries';
import { useNavigate, useParams } from 'react-router-dom';

import { PageWrapper } from 'components/ui';
import { getInputDir } from 'utils/helpers';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getFileUrl } from 'utils/file';
import { useSubscriptionFeatures } from '../hooks';

import { GetFeatureItemsByIdQuery } from '@/generated/graphql';

type OptionType = {
  limit: string;
  name: Record<string, string>;
  type: number;
};

type ValuesType = {
  subscription_feature_id: string;
  unit: string;
  option_1: OptionType;
  option_2: OptionType;
  option_3: OptionType;
};

const SubscriptionFeaturesDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { modal } = App.useApp();
  const navigate = useNavigate();
  const client = useApolloClient();
  const [form] = Form.useForm();
  const [duration, setDuration] = useState(DURATION_TYPE.month);

  const features = useSubscriptionFeatures();

  const { loading } = useQuery<GetFeatureItemsByIdQuery>(
    GET_SUBSCRIPTION_FEATURE_ITEM_BY_ID,
    {
      fetchPolicy: 'no-cache',
      skip: !id,
      variables: {
        id
      },
      onCompleted(data) {
        const featureItem = data?.getFeatureItemsById?.data;

        if (!featureItem) {
          return;
        }

        const optionValues = featureItem.options?.reduce((acc, item) => {
          if (item && item.duration_type && item.name) {
            const { duration_type, limit, name, type } = item;

            acc[`option_${duration_type}`] = {
              name,
              limit,
              type
            };
          }

          return acc;
        }, {} as Record<string, Record<string, number | number | null | undefined>>);

        form.setFieldsValue({
          subscription_feature_id: featureItem.subscription_feature_id,
          unit: featureItem.unit,
          ...optionValues
        });
      }
    }
  );

  const unitValue = Form.useWatch('unit', form);

  const isHiddenOptions = () => {
    if (!unitValue) {
      return true;
    }

    switch (unitValue) {
      case UNIT_OPTIONS.has_access:
      case UNIT_OPTIONS.no_access:
        return true;
      default:
        return false;
    }
  };

  const [createFeatureItem, { loading: createLoading }] = useMutation(
    CREATE_SUBSCRIPTION_FEATURE_ITEM,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_SUBSCRIPTION_FEATURE_ITEMS] });
        modal.success({
          title: t('messages.success'),
          content: t('subscription.create_feature_success')
        });
        navigate('/subscriptions/features');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const [updateFeatureItem, { loading: updateLoading }] = useMutation(
    UPDATE_SUBSCRIPTION_FEATURE_ITEM,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_SUBSCRIPTION_FEATURE_ITEMS] });
        modal.success({
          title: t('messages.success'),
          content: t('subscription.update_feature_success')
        });
        navigate('/subscriptions/features');
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onFinish = (values: ValuesType) => {
    const { option_1, option_2, option_3, ...rest } = values;
    const hideOptions = isHiddenOptions();

    const formattedOption = [
      {
        ...option_1,
        duration_type: DURATION_TYPE.month
      },
      {
        ...option_2,
        duration_type: DURATION_TYPE.year
      },
      {
        ...option_3,
        duration_type: DURATION_TYPE.free
      }
    ];

    const formattedValues = {
      ...rest,
      value: 0,
      ...(hideOptions ? { options: null } : { options: formattedOption })
    };

    if (id) {
      return updateFeatureItem({ variables: { input: formattedValues, id } });
    }

    return createFeatureItem({ variables: { input: formattedValues } });
  };

  const onFinishFailed: FormProps['onFinishFailed'] = e => {
    const firstErrorName = e.errorFields?.[0]?.name?.[0];
    const errorDuration =
      typeof firstErrorName === 'string' && firstErrorName?.includes('option_')
        ? +firstErrorName.replace('option_', '')
        : -1;

    if (errorDuration > -1) {
      setDuration(errorDuration);
    }
  };

  const onDurationChange = (newDuration: number) => {
    setDuration(newDuration);
  };

  return (
    <PageWrapper
      back
      loading={loading}
      color="white"
      title={t(`subscription.${id ? 'update' : 'create'}_subscription_feature`)}
      extra={[
        <Button
          key="submit-button"
          disabled={createLoading || updateLoading}
          loading={createLoading || updateLoading}
          type="primary"
          onClick={() => form.submit()}
          style={{ width: 150 }}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              name="subscription_feature_id"
              label={t('subscription.select_feature')}
              rules={[{ required: true }]}
            >
              <Select
                size="large"
                placeholder={t('subscription.select_feature')}
              >
                {features.map(item => (
                  <Select.Option
                    key={`feature-item-${item.id}`}
                    value={item.id}
                  >
                    <Flex gap={8} align="center">
                      <img
                        src={getFileUrl(item.icon)}
                        alt={item.name}
                        style={{ objectFit: 'contain', width: 20, height: 20 }}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.name.replace('<br />', '')
                        }}
                      />
                    </Flex>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="unit"
              label={t('subscription.set_measurement_unit')}
              rules={[{ required: true }]}
            >
              <Select size="large" placeholder={t('subscription.set_unit')}>
                {UNIT_OPTIONS_DATA.map(item => (
                  <Select.Option
                    key={`unit-option-${item.label}`}
                    value={item.value}
                  >
                    {t(`subscription.${item.label}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {!isHiddenOptions() && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Segmented
                options={DURATION_TYPE_DATA.map(item => ({
                  ...item,
                  label: t(`subscription.${item.label}`)
                }))}
                block
                size="large"
                value={duration}
                onChange={e => onDurationChange(e as number)}
              />
            </Col>
            <Col span={24}>
              {DURATION_TYPE_DATA.map(item => {
                return (
                  <div
                    style={{
                      display: item.value === duration ? 'block' : 'none'
                    }}
                    key={`form-${item.value}`}
                  >
                    <Row gutter={[16, 0]}>
                      {LANGUAGES.map(lang => (
                        <Col span={12} key={`${item.value}-${lang.key}`}>
                          <Form.Item
                            name={[`option_${item.value}`, 'name', lang.locale]}
                            label={`Feature value in ${lang.label}`}
                          >
                            <Input
                              size="large"
                              dir={getInputDir(lang.locale)}
                            />
                          </Form.Item>
                        </Col>
                      ))}
                      <Col span={12}>
                        <Form.Item
                          name={[`option_${item.value}`, 'type']}
                          label={t('subscription.limit_type')}
                          rules={[{ required: true }]}
                        >
                          <Select
                            size="large"
                            placeholder={t('subscription.set_limit')}
                          >
                            {LIMIT_TYPE_DATA.map(item => (
                              <Select.Option
                                key={`unit-option-${item.label}`}
                                value={item.value}
                              >
                                {t(`subscription.${item.label}`)}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[`option_${item.value}`, 'limit']}
                          label={t('subscription.limit')}
                          rules={[
                            {
                              required: false,
                              pattern: /^\d+(\.\d+)?$/,
                              message: 'Please enter a valid number'
                            }
                          ]}
                        >
                          <Input size="large" min={1} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Col>
          </Row>
        )}
      </Form>
    </PageWrapper>
  );
};

export default SubscriptionFeaturesDetails;
