// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC, useRef } from 'react';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { CustomUploadAdapter } from 'utils/customUploadAdapter';

interface IProps {
  value?: string;
  onChange?(value: string): void;
  language?: string;
  disabled?: boolean;
}

const CKEditor: FC<IProps> = ({ value, onChange, language, disabled }) => {
  const ref = useRef<ClassicEditor>();

  return (
    <Editor
      editor={ClassicEditor}
      data={value || ''}
      onReady={editor => {
        const classicEditorInstance = editor as unknown as ClassicEditor;
        ref.current = classicEditorInstance;

        editor.plugins.get('FileRepository').createUploadAdapter = loader => {
          return new CustomUploadAdapter(loader);
        };
      }}
      config={{
        language,
        extraPlugins: [CustomUploadAdapter],
        mediaEmbed: {
          previewsInData: true
        }
      }}
      onChange={() => {
        onChange?.(ref.current?.getData() || '');
      }}
      disabled={disabled}
    />
  );
};

export default CKEditor;
