import React, { FC } from 'react';
import { Card, Flex, Image, Modal, Typography } from 'antd';
import { GET_CHAT_BY_ID } from 'gql/ai_health/queries';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { Message, MessageContainer } from './styled';
import { Loading } from 'components/ui';
import { getFileUrl } from 'utils/file';

import { GetChatByIdQuery } from '@/generated/graphql';

const { Text, Title } = Typography;

interface IProps {
  onClose(): void;
  id: string;
}

const ChatModal: FC<IProps> = ({ id, onClose }) => {
  const navigate = useNavigate();
  const { data, loading } = useQuery<GetChatByIdQuery>(GET_CHAT_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      id
    }
  });

  const chat = data?.getChatById.data;

  return (
    <Modal title={chat?.title} open footer={false} onCancel={onClose}>
      {loading ? (
        <Loading />
      ) : (
        chat?.messages.map(item => (
          <React.Fragment key={item.answer}>
            <MessageContainer type="question">
              <Text
                style={{
                  fontWeight: 'bold',
                  color: '#2552F3',
                  textAlign: 'right'
                }}
              >
                Question
              </Text>
              <Message type="question">
                <span dangerouslySetInnerHTML={{ __html: item.question }} />
              </Message>
            </MessageContainer>
            <MessageContainer type="answer">
              <Text style={{ fontWeight: 'bold', color: '#6B7083' }}>
                Answer
              </Text>
              <Message type="answer">
                <span dangerouslySetInnerHTML={{ __html: item.answer }} />
              </Message>
            </MessageContainer>
            {!!item?.doctors.length && (
              <MessageContainer type="answer">
                <Flex style={{ width: '100%' }} gap={8} vertical>
                  {item.doctors.map(doctor => (
                    <Card
                      hoverable
                      onClick={() =>
                        navigate(
                          `/access/doctors/${doctor.id}/personal-information?lang=en`
                        )
                      }
                      style={{ width: '80%', cursor: 'pointer' }}
                      key={doctor.id}
                    >
                      <Flex gap={16} align="center">
                        <Image
                          src={getFileUrl(doctor.avatar || '')}
                          width={42}
                          height={42}
                          preview={false}
                          style={{ borderRadius: '50%', objectFit: 'cover' }}
                        />
                        <Flex vertical>
                          <Title
                            level={5}
                          >{`${doctor.first_name} ${doctor.last_name}`}</Title>
                          <Flex gap={6}>
                            <Text>{doctor.organization.name}</Text>
                            <Image
                              src={getFileUrl(doctor.organization.image || '')}
                              width={24}
                              height={24}
                              preview={false}
                              style={{
                                borderRadius: '50%',
                                objectFit: 'cover'
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Card>
                  ))}
                </Flex>
              </MessageContainer>
            )}
          </React.Fragment>
        ))
      )}
    </Modal>
  );
};

export default ChatModal;
