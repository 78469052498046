import { QueryHookOptions, useQuery } from '@apollo/client';

import { GET_SUBSCRIPTION_PLANS } from '../queries';

import { GetSubscriptionsForAdminQuery } from '@/generated/graphql';

const useSubscriptionPlans = (
  options?: QueryHookOptions<GetSubscriptionsForAdminQuery>
) => {
  const { data, loading, refetch } = useQuery<GetSubscriptionsForAdminQuery>(
    GET_SUBSCRIPTION_PLANS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: 999
          }
        }
      },
      ...options
    }
  );

  return {
    results: data?.getSubscriptionsForAdmin.data.results || [],
    total: data?.getSubscriptionsForAdmin.data.total || 0,
    loading,
    refetch
  };
};

export default useSubscriptionPlans;
