import { useTranslation } from 'react-i18next';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { Button, Image, Table } from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { ColumnProps } from 'antd/es/table';
import { DELETE_SUBSCRIPTION_PLAN } from 'gql/subscription/mutations';
import { useUIDispatchContext } from 'providers/UI';
import { useSubscriptionPlans } from 'gql/subscription/hooks';

import { Private } from 'components/shared';
import { ActionMenu, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getFileUrl } from 'utils/file';

import { GetSubscriptionsForAdminQuery } from '@/generated/graphql';

type ColumnType = NonNullable<
  NonNullable<GetSubscriptionsForAdminQuery['getSubscriptionsForAdmin']>['data']
>['results'][0];

const SubscriptionPlans = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toggleModal } = useUIDispatchContext();
  const { results, loading, refetch } = useSubscriptionPlans();

  const [deleteSubscriptionPlan] = useMutation(DELETE_SUBSCRIPTION_PLAN, {
    onCompleted() {
      refetch();
    },
    onError(err) {
      showErrorMessage(err);
    }
  });

  const onDeletePlan = useCallback(
    (id: string) => {
      toggleModal('confirm', true, {
        title: t('subscription.delete_subscription_plan'),
        onConfirm: () => deleteSubscriptionPlan({ variables: { id } })
      });
    },
    [deleteSubscriptionPlan, t, toggleModal]
  );

  const columns: ColumnProps<ColumnType>[] = useMemo(
    () => [
      {
        title: t('subscription.image'),
        dataIndex: 'icon',
        key: 'icon',
        render: (icon: string) => (
          <Image
            width={30}
            height={30}
            src={getFileUrl(icon)}
            preview={false}
          />
        )
      },
      {
        title: t('subscription.plan_name'),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        render: (_, { id }) => (
          <ActionMenu
            data={[
              {
                title: t('table.edit'),
                permission: {
                  route: PERMISSION_ROUTES.faq,
                  action: PERMISSION_ACTIONS.update
                },
                action() {
                  navigate(`/subscriptions/plans/${id}`);
                },
                menuProps: {
                  style: {
                    color: 'var(--primary)'
                  }
                }
              },
              {
                title: t('table.delete'),
                permission: {
                  route: PERMISSION_ROUTES.faq,
                  action: PERMISSION_ACTIONS.delete
                },
                action() {
                  onDeletePlan(id);
                },
                menuProps: {
                  style: {
                    color: 'var(--danger-color)'
                  }
                }
              }
            ]}
            placement="bottomRight"
            icon={<EllipsisOutlined />}
          />
        )
      }
    ],
    [navigate, onDeletePlan, t]
  );

  return (
    <PageWrapper
      title={t('subscription.subscription_plans')}
      color="white"
      extra={
        results?.length
          ? []
          : [
              <Private
                key="add-blog-button"
                route={PERMISSION_ROUTES.blog}
                action={PERMISSION_ACTIONS.create}
              >
                <Button
                  // disabled={total >= 3}
                  type="primary"
                  onClick={() => navigate('/subscriptions/plans/details')}
                >
                  <PlusOutlined />
                  {t('subscription.create_plan')}
                </Button>
              </Private>
            ]
      }
    >
      <Table
        rowKey="id"
        columns={columns}
        dataSource={results}
        loading={loading}
        pagination={false}
      />
    </PageWrapper>
  );
};

export default SubscriptionPlans;
