import { FC } from 'react';
import { Flex, Form, Select, Typography } from 'antd';
import { useSubscriptionPlans } from 'gql/subscription/hooks';

import { getFileUrl } from 'utils/file';

const SubscriptionSelect: FC = () => {
  const { results: subscriptions } = useSubscriptionPlans();

  const data = subscriptions.map(item => ({
    icon: item.icon,
    label: item.name,
    value: item.id
  }));

  return (
    <Form.Item style={{ flex: 1 }} label="Subscription" name="subscription">
      <Select placeholder="Subscription" allowClear>
        {data.map(item => (
          <Select.Option key={item.value} value={item.value}>
            <Flex gap={8} align="center">
              {!!item.icon && (
                <img
                  src={getFileUrl(item.icon)}
                  width={16}
                  height={16}
                  style={{ objectFit: 'contain' }}
                />
              )}
              <Typography.Text>{item.label}</Typography.Text>
            </Flex>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SubscriptionSelect;
