import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';

import { PageWrapper } from 'components/ui';
import { CountriesChart, VisitorsChart } from './components';

const chartData = [
  { month: 'Apr 21', male: 300, female: 250 },
  { month: 'May 21', male: 320, female: 280 },
  { month: 'Jun 21', male: 340, female: 300 },
  { month: 'Jul 21', male: 140, female: 200 }
];

const chartData2 = [
  { month: 'Apr 21', male: 300, female: 150 },
  { month: 'May 21', male: 320, female: 280 },
  { month: 'Jun 21', male: 340, female: 80 },
  { month: 'Jul 21', male: 140, female: 100 }
];

const countryData = [
  { country: 'Indonesia', value: 33 },
  { country: 'Bangladesh', value: 29 },
  { country: 'Senegal', value: 27 },
  { country: 'Irak', value: 24 },
  { country: 'Pakistan', value: 43 },
  { country: 'Singapore', value: 20 },
  { country: 'Australia', value: 8 },
  { country: 'Russia', value: 3 }
];

const PartnerAnalytic = () => {
  const { t } = useTranslation();
  // const params = useParams();
  // const partnerId = params?.id;

  // console.log(partnerId);

  return (
    <Card bodyStyle={{ padding: 4 }} style={{ margin: '32px 24px' }}>
      <PageWrapper color="white" back title={t('partners.analytics')}>
        <Row gutter={[30, 30]}>
          <Col span={14}>
            <Row gutter={[30, 30]}>
              <Col span={24}>
                <VisitorsChart id="first-chart" data={chartData} />
              </Col>
              <Col span={24}>
                <VisitorsChart id="second-chart" data={chartData2} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <CountriesChart data={countryData} />
          </Col>
        </Row>
      </PageWrapper>
    </Card>
  );
};

export default PartnerAnalytic;
