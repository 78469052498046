import { useApolloClient, useMutation } from '@apollo/client';
import { App, Button, Flex, Form } from 'antd';
import { UPDATE_PROFILE } from 'gql/doctors/mutations';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { FC, memo } from 'react';
import { usePermission } from 'hooks';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';

import { showErrorMessage } from 'utils/showErrorMessage';
import { CKEditor, OrganizationResult, Private } from 'components/shared';
import useDoctorProfileCache from 'hooks/useDoctorProfileCache';

interface IProps {
  id: string;
  lang: string;
  allowToEdit?: boolean;
}

const ProfileDetails: FC<IProps> = ({ id, lang, allowToEdit }) => {
  const { checkUserPermission } = usePermission();
  const client = useApolloClient();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { profile, validateProfile } = useDoctorProfileCache({ id, lang });

  const [updateProfile, { loading: isUpdating }] = useMutation(UPDATE_PROFILE, {
    onCompleted() {
      message.success('Successfully saved');
      client.refetchQueries({ include: [GET_DOCTOR_PROFILE] });
    },
    onError: err => showErrorMessage(err)
  });

  const onFinish = (values: { about: string }) => {
    validateProfile(() => {
      updateProfile({
        variables: {
          input: {
            first_name: profile?.first_name || '',
            last_name: profile?.last_name || '',
            languages: profile?.languages,
            gender: profile?.gender || '',
            phone: profile?.phone || '',
            address: profile?.address || '',
            timezone: profile?.timezone || '',
            specialties: profile?.specialties?.map(item => +item.id) || [],
            about: values?.about,
            user_id: id || profile?.id,
            lang
          }
        }
      });
    });
  };

  const hasProfileUpdatePermission = checkUserPermission(
    PERMISSION_ROUTES.doctors,
    PERMISSION_ACTIONS.update
  );

  return (
    <OrganizationResult>
      <Form
        form={form}
        layout="vertical"
        size="large"
        disabled={!hasProfileUpdatePermission}
        initialValues={{
          about: profile?.about
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="about"
          label="About doctor"
          rules={[{ required: true }]}
        >
          <CKEditor disabled={!allowToEdit} />
        </Form.Item>
        <Flex justify="end" gap={12}>
          <Private
            route={PERMISSION_ROUTES.doctors}
            action={PERMISSION_ACTIONS.update}
          >
            <Button
              size="large"
              type="primary"
              onClick={form.submit}
              loading={isUpdating}
              disabled={!allowToEdit}
            >
              Save
            </Button>
          </Private>
        </Flex>
      </Form>
    </OrganizationResult>
  );
};

export default memo(ProfileDetails);
