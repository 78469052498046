// UNIT
export const SUBSCRIPTION_UNIT_WEEK = 1;
export const SUBSCRIPTION_UNIT_MONTH = 2;
export const SUBSCRIPTION_UNIT_PERCENT = 3;
export const SUBSCRIPTION_UNIT_GB = 4;
export const SUBSCRIPTION_UNIT_HAS_ACCESS = 5;
export const SUBSCRIPTION_UNIT_NO_ACCESS = 6;

export const UNIT_OPTIONS = {
  week: SUBSCRIPTION_UNIT_WEEK,
  month: SUBSCRIPTION_UNIT_MONTH,
  percent: SUBSCRIPTION_UNIT_PERCENT,
  gb: SUBSCRIPTION_UNIT_GB,
  has_access: SUBSCRIPTION_UNIT_HAS_ACCESS,
  no_access: SUBSCRIPTION_UNIT_NO_ACCESS
} as const;

export const UNIT_OPTIONS_VALUES = {
  [SUBSCRIPTION_UNIT_WEEK]: 'week',
  [SUBSCRIPTION_UNIT_MONTH]: 'month',
  [SUBSCRIPTION_UNIT_PERCENT]: 'percent',
  [SUBSCRIPTION_UNIT_GB]: 'gb',
  [SUBSCRIPTION_UNIT_HAS_ACCESS]: 'has_access',
  [SUBSCRIPTION_UNIT_NO_ACCESS]: 'no_access'
} as const;

export const UNIT_OPTIONS_DATA = [
  {
    label: 'week',
    value: UNIT_OPTIONS.week
  },
  {
    label: 'month',
    value: UNIT_OPTIONS.month
  },
  {
    label: 'percent',
    value: UNIT_OPTIONS.percent
  },
  {
    label: 'gb',
    value: UNIT_OPTIONS.gb
  },
  {
    label: 'has_access',
    value: UNIT_OPTIONS.has_access
  },
  {
    label: 'no_access',
    value: UNIT_OPTIONS.no_access
  }
];

// LIMIT

export const SUBSCRIPTION_LIMIT_LIMITED = 1;
export const SUBSCRIPTION_LIMIT_UNLIMITED = 2;

export const SUBSCRIPTION_LIMIT_OPTIONS = {
  limited: SUBSCRIPTION_LIMIT_LIMITED,
  unlimited: SUBSCRIPTION_LIMIT_UNLIMITED
};

export const SUBSCRIPTION_LIMIT_OPTIONS_VALUES = {
  [SUBSCRIPTION_LIMIT_LIMITED]: 'limited',
  [SUBSCRIPTION_LIMIT_UNLIMITED]: 'unlimited'
} as const;

// DURATION

export const SUBSCRIPTION_MONTHLY_DURATION = 1;
export const SUBSCRIPTION_YEARLY_DURATION = 2;
export const SUBSCRIPTION_FREE_DURATION = 3;

export const DURATION_TYPE = {
  month: SUBSCRIPTION_MONTHLY_DURATION,
  year: SUBSCRIPTION_YEARLY_DURATION,
  free: SUBSCRIPTION_FREE_DURATION
};

export const DURATION_TYPE_VALUES = {
  [SUBSCRIPTION_MONTHLY_DURATION]: 'month',
  [SUBSCRIPTION_YEARLY_DURATION]: 'year',
  [SUBSCRIPTION_FREE_DURATION]: 'free'
};

export const DURATION_TYPE_DATA = [
  {
    label: 'monthly',
    value: DURATION_TYPE.month
  },
  {
    label: 'yearly',
    value: DURATION_TYPE.year
  },
  {
    label: 'free',
    value: DURATION_TYPE.free
  }
];

export const LIMIT_TYPE_DATA = [
  {
    label: 'limited',
    value: SUBSCRIPTION_LIMIT_OPTIONS.limited
  },
  {
    label: 'unlimited',
    value: SUBSCRIPTION_LIMIT_OPTIONS.unlimited
  }
];

//STATUS
export const SUBSCRIPTION_ACTIVE_STATUS = 1;
export const SUBSCRIPTION_INACTIVE_STATUS = 2;
export const SUBSCRIPTION_ARCHIVED_STATUS = 3;

export const SUBSCRIPTION_STATUSES = {
  active: SUBSCRIPTION_ACTIVE_STATUS,
  inactive: SUBSCRIPTION_INACTIVE_STATUS,
  archived: SUBSCRIPTION_ARCHIVED_STATUS
};

export const SUBSCRIPTION_STATUS_VALUES = {
  [SUBSCRIPTION_ACTIVE_STATUS]: 'active',
  [SUBSCRIPTION_INACTIVE_STATUS]: 'inactive',
  [SUBSCRIPTION_ARCHIVED_STATUS]: 'archived'
};
