import {
  App,
  Button,
  Card,
  Col,
  ColorPicker,
  Flex,
  Form,
  Image,
  Input,
  Row,
  Spin,
  Typography
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFileUpload } from 'hooks';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_PARTNER, UPDATE_PARTNER } from 'gql/partners/mutation';
import { useEffect, useState } from 'react';
import { GET_PARTNER_BY_ID } from 'gql/partners/queries';
import { CloudUploadOutlined, DownOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';

import { Loading, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { QrView } from './components';
import { OrganizationAlert } from 'components/shared';

import { GetPartnerQuery, PartnerBodyInput } from '@/generated/graphql';

const { Paragraph } = Typography;

const itemValues = {
  name: '',
  logo: '',
  image: ''
};

type ValuesType = typeof itemValues;
type BodyType = Record<string, ValuesType>;

const PartnerDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const partnerId = params?.id;
  const { message } = App.useApp();
  const [qrColor, setQrColor] = useState('#18212C');
  const [open, setOpen] = useState(false);

  const {
    fileUrl: fileLogo,
    loading: isUploadingLogo,
    result: fileValueLogo,
    setResult: setLogoResult,
    upload: uploadLogo
  } = useFileUpload({
    handleResult: true,
    loading: true
  });

  // const {
  //   fileUrl: file,
  //   loading: isUploading,
  //   result: fileValue,
  //   setResult: setImageResult,
  //   upload
  // } = useFileUpload({
  //   handleResult: true,
  //   loading: true
  // });

  const { data, loading } = useQuery<GetPartnerQuery>(GET_PARTNER_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !partnerId,
    variables: {
      id: partnerId
    }
  });

  const partnerById = data?.getPartner?.data;

  const [createPartner, { loading: isCreating }] =
    useMutation<PartnerBodyInput>(CREATE_PARTNER, {
      onCompleted() {
        message.success(t('partners.successfully_created'));
        navigate('/partner/');
      },
      onError: err => showErrorMessage(err)
    });

  const [updatePartner, { loading: isUpdating }] =
    useMutation<PartnerBodyInput>(UPDATE_PARTNER, {
      onCompleted() {
        message.success(t('partners.successfully_updated'));
        navigate('/partner/');
      },
      onError: err => showErrorMessage(err)
    });

  const onFinish = (values: BodyType) => {
    delete values.website;
    delete values.email;
    delete values.title;
    delete values.qr_color;

    const input = {
      ...values,
      logo: fileValueLogo
      // image: fileValue
    };

    if (partnerId) {
      updatePartner({
        variables: {
          id: partnerId,
          input
        }
      });

      return;
    }

    createPartner({
      variables: {
        input
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      logo: partnerById?.logo,
      name: partnerById?.name,
      image: partnerById?.image
    });
    // setImageResult(partnerById?.image || '');
    setLogoResult(partnerById?.logo || '');
  }, [form, partnerById, setLogoResult]);

  const renderDefaultLogo = () => {
    if (isUploadingLogo) {
      return <Spin />;
    }

    if (fileValueLogo && fileLogo) {
      return (
        <Image
          src={fileLogo}
          preview={false}
          height={150}
          style={{ objectFit: 'cover', cursor: 'pointer' }}
        />
      );
    }

    return (
      <Flex vertical align="center">
        <CloudUploadOutlined
          style={{ fontSize: 16, color: 'var(--primary)' }}
        />
        <Paragraph style={{ color: 'var(--primary)', margin: 0 }}>
          {t('partners.upload_logo')}
        </Paragraph>
      </Flex>
    );
  };

  return (
    <Card bodyStyle={{ padding: 4 }} style={{ margin: '32px 24px' }}>
      <PageWrapper
        color="white"
        back
        title={t(`partners.${partnerId ? 'update' : 'create'}_partner`)}
      >
        {loading ? (
          <Loading />
        ) : (
          <Form form={form} onFinish={onFinish} layout="vertical" size="large">
            <Row
              justify="space-evenly"
              gutter={[20, 20]}
              style={{ marginBottom: '--margin-md' }}
            >
              <Col span={14}>
                <Paragraph style={{ fontSize: 18, fontWeight: 600 }}>
                  {t('partners.company_information')}
                </Paragraph>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      rules={[{ required: true }]}
                      label={t('partners.company_name')}
                    >
                      <Input placeholder={t('partners.enter_name')} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="website"
                      // rules={[{ required: true }]}
                      label={t('partners.company_website')}
                    >
                      <Input placeholder="E.g. https://www.company..." />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      // rules={[{ required: true }]}
                      label={t('partners.company_email')}
                    >
                      <Input placeholder={t('partners.enter_email')} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="title"
                      // rules={[{ required: true }]}
                      label={t('table.title')}
                    >
                      <Input placeholder={t('partners.enter_title')} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="logo"
                      rules={[{ required: true }]}
                      label={t('partners.company_logo')}
                    >
                      <Dragger
                        multiple={false}
                        maxCount={1}
                        showUploadList={false}
                        accept={'image/jpeg, image/png'}
                        customRequest={e => uploadLogo(e.file as File)}
                      >
                        {renderDefaultLogo()}
                      </Dragger>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="qr_color" label={t('partners.qr_color')}>
                      <ColorPicker
                        defaultValue="#18212C"
                        open={open}
                        onOpenChange={setOpen}
                        onChangeComplete={color =>
                          setQrColor(color.toHexString())
                        }
                        showText={() => (
                          <Flex align="center" gap={4}>
                            {t('partners.choose_color')}
                            <DownOutlined
                              style={{ fontSize: 14 }}
                              rotate={open ? 180 : 0}
                            />
                          </Flex>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Col>
                <Form.Item label={t('partners.image')} name="image">
                  <Upload
                    multiple={false}
                    maxCount={1}
                    showUploadList={false}
                    accept={'image/jpeg, image/png'}
                    customRequest={e => upload(e.file as File)}
                  >
                    {isUploading ? (
                      <Spin />
                    ) : (
                      <Image
                        src={file}
                        width={300}
                        height={200}
                        preview={false}
                        style={{ objectFit: 'cover', cursor: 'pointer' }}
                      />
                    )}
                  </Upload>
                </Form.Item>
              </Col> */}
              </Col>
              <Col span={7}>
                <QrView color={qrColor} />
              </Col>
            </Row>
            <Flex style={{ marginTop: 30 }} gap={16} justify="center">
              <OrganizationAlert>
                <Button
                  style={{ width: 110 }}
                  type="primary"
                  ghost
                  key="save-partner"
                  loading={isCreating || isUpdating}
                  onClick={form.submit}
                >
                  {t('common.save')}
                </Button>
              </OrganizationAlert>

              {/* <Button
              type="primary"
              key="save-qr"
              loading={isCreating || isUpdating}
              onClick={form.submit}
            >
              {t('partners.save_send_qr')}
            </Button> */}
            </Flex>
          </Form>
        )}
      </PageWrapper>
    </Card>
  );
};

export default PartnerDetails;
