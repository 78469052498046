import { gql } from '@apollo/client';

import {
  APPOINTMENTS_FRAGMENT,
  APPOINTMENT_FILE_FRAGMENT,
  APPOINTMENT_ITEM_FRAGMENT,
  FAST_APPOINTMENT_FRAGMENT,
  FAST_TREATMENT_FRAGMENT
} from './fragments';

export const GET_APPOINTMENTS = gql`
  query appointmentsForAdmin($query: GetAppointmentsInput!) {
    appointmentsForAdmin(query: $query) {
      data {
        results {
          ...AppointmentsFragment
        }
        total
      }
    }
  }
  ${APPOINTMENTS_FRAGMENT}
`;

export const GET_APPOINTMENT_ITEM = gql`
  query AppointmentById($id: ID!) {
    appointmentForAdmin(id: $id) {
      data {
        ...AppointmentItem
      }
    }
  }
  ${APPOINTMENT_ITEM_FRAGMENT}
`;

export const GET_PREVIOUS_FILES = gql`
  query PreviousFiles($id: ID!) {
    previousFiles(id: $id) {
      data {
        ...AppointmentFileFragment
        appointment_id
      }
    }
  }
  ${APPOINTMENT_FILE_FRAGMENT}
`;

export const GET_APPOINTMENT_BY_CREATOR = gql`
  query AppointmentByCreator($query: Pagination!) {
    appointmentByCreator(query: $query) {
      data {
        results {
          id
          created_at
          updated_at
        }
        total
      }
    }
  }
`;

export const GET_PAID_APPOINTMENT_BY_CREATOR = gql`
  query AppointmentByCreatorPay($query: Pagination!) {
    appointmentByCreatorPay(query: $query) {
      data {
        results {
          id
          url
          price
          paid
          reason
          created_at
          updated_at
        }
        total
      }
    }
  }
`;

export const GET_TRANSLATION_REQUESTS = gql`
  query TranslatorRequests($query: Pagination) {
    translatorRequests(query: $query) {
      data {
        results {
          id
          appointment {
            id
            created_at
            doctor {
              id
              first_name
              last_name
              avatar
            }
            patient {
              id
              first_name
              last_name
              avatar
            }
            status
            price
            paid
          }
          translator_plan {
            price
            lang_1
            lang_2
          }
        }
        total
      }
    }
  }
`;

export const GET_PATIENT_REQUESTS = gql`
  query GetPatientRequests($query: IFastAppointmentInput) {
    getFastAppointments(query: $query) {
      data {
        results {
          ...FastAppointmentFragment
        }
        total
      }
    }
  }
  ${FAST_APPOINTMENT_FRAGMENT}
`;

export const GET_PATIENT_REQUESTS_COUNT = gql`
  query GetPatientRequestsCount($query: IFastAppointmentInput) {
    getFastAppointments(query: $query) {
      data {
        total
      }
    }
  }
`;

export const GET_PATIENT_REQUEST_BY_ID = gql`
  query GetPatientRequestById($id: ID!) {
    fastAppointmentById(id: $id) {
      data {
        ...FastAppointmentFragment
        diagnosis
        files
        organization_info
        links
        note
        notes
      }
    }
  }
  ${FAST_APPOINTMENT_FRAGMENT}
`;

export const GET_MEDICAL_TREATMENT = gql`
  query GetTreatmentForms($query: treatmentFormsQuery) {
    getTreatmentForms(query: $query) {
      data {
        results {
          ...FastTreatmentFragment
        }
        total
      }
    }
  }
  ${FAST_TREATMENT_FRAGMENT}
`;

export const GET_MEDICAL_TREATMENT_BY_ID = gql`
  query GetTreatmentFormById($id: ID!) {
    getTreatmentFormById(id: $id) {
      data {
        ...FastTreatmentFragment
        note
        description
        created_at
        calculations
        history {
          updated_at
          treatment_form_id
          status
          note
          id
          created_at
        }
        files {
          id
          filename
        }
      }
    }
  }
  ${FAST_TREATMENT_FRAGMENT}
`;
