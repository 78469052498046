import { gql } from '@apollo/client';

export const GET_PARTNERS = gql`
  query GetPartners($query: PartnerQueryInput!) {
    getPartners(query: $query) {
      data {
        total
        results {
          code
          created_at
          id
          image
          logo
          name
          reason
          status
        }
      }
    }
  }
`;

export const GET_PARTNER_BY_ID = gql`
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      data {
        status
        reason
        name
        logo
        image
        id
        code
      }
    }
  }
`;
