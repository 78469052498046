import { FC } from 'react';
import { Col, Form, Switch } from 'antd';

import { FieldTypes } from './_types';
import Field from './Field';

interface IProps {
  name: string;
  label: string;
  type: FieldTypes;
  length: number;
  disabled?: boolean;
  onSwitchChange(name: string, checked: boolean): void;
}

const Renderer: FC<IProps> = ({
  name,
  label,
  type,
  length,
  disabled,
  onSwitchChange
}) => {
  switch (type) {
    case FieldTypes.field: {
      return <Field name={name} label={label} length={length} />;
    }

    case FieldTypes.switch:
      return (
        <Col xs={24}>
          <Form.Item
            className="checkbox-form-item"
            name={name}
            label={label}
            valuePropName="checked"
          >
            <Switch
              disabled={disabled}
              onChange={value => onSwitchChange(name, value)}
            />
          </Form.Item>
        </Col>
      );
    default:
      return null;
  }
};

export default Renderer;
