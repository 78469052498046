import { gql } from '@apollo/client';

import { FEATURE_ITEM_MINI_FRAGMENT } from './fragments';

export const GET_SUBSCRIPTION_PLANS = gql`
  query GetSubscriptionsForAdmin($query: SubscriptionsQuery) {
    getSubscriptionsForAdmin(query: $query) {
      data {
        total
        results {
          id
          icon
          name
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_PLAN_BY_ID = gql`
  query GetSubscriptionById($id: ID!) {
    getSubscriptionById(id: $id) {
      data {
        id
        name
        icon
        features {
          id
          icon
          name
          feature
          is_active
        }
        options {
          price
          most_popular
          duration_type
          source
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_FEATURE_ITEMS = gql`
  query GetFeatureItems($query: FeatureItemsQuery) {
    getFeatureItems(query: $query) {
      data {
        total
        results {
          ...FeatureItemMiniFragment
        }
      }
    }
  }
  ${FEATURE_ITEM_MINI_FRAGMENT}
`;

export const GET_SUBSCRIPTION_FEATURE_ITEM_BY_ID = gql`
  query GetFeatureItemsById($id: ID!) {
    getFeatureItemsById(id: $id) {
      data {
        id
        value
        unit
        subscription_feature_id
        options {
          id
          type
          limit
          name
          duration_type
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_FEATURES = gql`
  query GetSubscriptionFeatures($query: SubscriptionsFeaturesQuery) {
    getSubscriptionFeatures(query: $query) {
      data {
        results {
          id
          icon
          name
        }
      }
    }
  }
`;
