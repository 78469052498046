import { FC, Fragment } from 'react';
import { App, Button, Flex, Form, Row, Typography } from 'antd';

import { StyledPage } from './styled';
import useOrganizationSettings from 'pages/OrganizationSettings/hooks/useUpdateSettigns';
import Renderer from './Renderer';
import { FormValues } from './_types';
import { DEFAULT_VALUES, FIELDS_DATA } from './_constants';

interface IProps {
  id: string;
}

const Pricing: FC<IProps> = ({ id }) => {
  const { modal } = App.useApp();
  const [form] = Form.useForm<FormValues>();

  const { loading, settings, onUpdateSettings } = useOrganizationSettings(id);

  const calculations = settings?.calculations || {};

  const onFinish = (values: FormValues) => {
    onUpdateSettings({
      calculations: {
        ...values,
        treatmentPlanning: true
      }
    });
  };

  const onReset = () => {
    modal.confirm({
      title: 'Waring',
      content: (
        <Typography.Text>
          Are you sure you want to reset settings to default?{' '}
          <b>Changes will be automatically saved.</b>
        </Typography.Text>
      ),
      onOk() {
        form.setFieldsValue({
          ...DEFAULT_VALUES
        });
        form.submit();
      }
    });
  };

  const onSwitchChange = (name: string, checked: boolean) => {
    if (!checked) {
      const values = form.getFieldsValue();

      const newValues = Object.entries(values).reduce((acc, [key, value]) => {
        if (key !== name && key.includes(name) && value) {
          return {
            ...acc,
            [key]: 0
          };
        }

        return acc;
      }, values);

      form.setFieldsValue({
        ...newValues
      });
    }
  };

  return (
    <StyledPage>
      <Form
        initialValues={Object.keys(DEFAULT_VALUES).reduce(
          (acc, key) => ({
            ...acc,
            [key]:
              calculations?.[key] ||
              DEFAULT_VALUES[key as keyof typeof DEFAULT_VALUES]
          }),
          {}
        )}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size="large"
      >
        {FIELDS_DATA.map((group, groupIndex) => (
          <Row key={groupIndex} gutter={24}>
            {group.map((field, _, arr) => (
              <Fragment key={field.name}>
                <Renderer
                  disabled={field.disabled}
                  name={field.name}
                  label={field.label}
                  type={field.type}
                  length={arr.length}
                  onSwitchChange={onSwitchChange}
                />
              </Fragment>
            ))}
          </Row>
        ))}
      </Form>
      <Flex justify="end" gap={12}>
        <Button size="large" onClick={onReset} disabled={loading}>
          Reset to default
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={form.submit}
          loading={loading}
        >
          Save
        </Button>
      </Flex>
    </StyledPage>
  );
};

export default Pricing;
