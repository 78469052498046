import { FC, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Card, Empty, Flex, Typography } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { GenderCircle } from './styled';

const { Paragraph, Text } = Typography;

const ChartContainer = styled.div`
  position: relative;
  height: 160px;

  .remove_logo {
    position: absolute;
    width: 100%;
    height: 19px;
    background: white;
    bottom: 0;
  }

  > div {
    height: 100%;
  }
`;

interface IProps {
  id: string;
  data: {
    month: string;
    male: number;
    female: number;
  }[];
}

const VisitorsChart: FC<IProps> = ({ data, id }) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (!data?.length) {
      return;
    }

    const root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        paddingLeft: 0,
        layout: root.verticalLayout
      })
    );

    const xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minorGridEnabled: false
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'month',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
      strokeOpacity: 0
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
          minGridDistance: 30
        })
      })
    );

    yAxis.get('renderer')?.grid.template.setAll({
      strokeOpacity: 0
    });

    xAxis.data.setAll(data);

    const makeVisitors = (name: string, fieldName: string) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: 'month'
        })
      );

      series.columns.template.setAll({
        cornerRadiusTL: 5,
        cornerRadiusTR: 5,
        tooltipText: `{name}, {categoryX}: {valueY}`,
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
        fillOpacity: 1,
        fill: name === 'Male' ? am5.color(0x1442db) : am5.color(0x35b678),
        stroke: name === 'Male' ? am5.color(0x1442db) : am5.color(0x35b678)
      });

      series.data.setAll(data);

      series.bullets.push(() =>
        am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: '{valueY}',
            fill: root.interfaceColors.get('alternativeText'),
            centerY: 0,
            centerX: am5.percent(50),
            populateText: true
          })
        })
      );
    };

    makeVisitors('Male', 'male');
    makeVisitors('Female', 'female');

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, id]);

  return data ? (
    <Card
      title={
        <Flex justify="space-between">
          <Paragraph style={{ fontSize: 20, fontWeight: 500, margin: 0 }}>
            {t('partners.visitor')}
          </Paragraph>
          <Flex gap={33}>
            <Flex align="center" gap={6}>
              <GenderCircle $gender="male" />
              <Text style={{ color: '#63687A' }}>{t('common.male')}</Text>
            </Flex>
            <Flex align="center" gap={6}>
              <GenderCircle />
              <Text style={{ color: '#63687A' }}>{t('common.female')}</Text>
            </Flex>
          </Flex>
          <div />
        </Flex>
      }
    >
      <ChartContainer>
        <div id={id} className="chartdiv" />
        <div className="remove_logo" />
      </ChartContainer>
    </Card>
  ) : (
    <Empty />
  );
};

export default VisitorsChart;
