import { FC, useCallback } from 'react';
import { App, Button, Dropdown, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { SEND_DOCTOR_EMAIL } from 'gql/users/mutations';
import { DoctorById } from 'gql/doctors/__generated__/DoctorById';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { SendOutlined } from '@ant-design/icons';
import { useUIDispatchContext } from 'providers/UI';

import { showErrorMessage } from 'utils/showErrorMessage';

interface IProps {
  id: string;
  lang: string;
  allowToEdit?: boolean;
}

const Send: FC<IProps> = ({ id, lang, allowToEdit }) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const { toggleModal } = useUIDispatchContext();
  const { data } = useQuery<DoctorById>(GET_DOCTOR_PROFILE, {
    nextFetchPolicy: 'cache-only',
    variables: {
      id,
      lang
    }
  });

  const profile = data?.doctorById?.data;

  const [sendEmail, { loading: isSending }] = useMutation(SEND_DOCTOR_EMAIL, {
    onCompleted() {
      message.success(t('messages.successfully_sending'));
    },
    onError: err => showErrorMessage(err)
  });

  const onSendEmailDoctor = useCallback(() => {
    modal.confirm({
      title: t('common.confirm'),
      content: t('common.sure'),
      onOk: () => {
        sendEmail({
          variables: {
            input: {
              email: profile?.email
            }
          }
        });
      }
    });
  }, [modal, t, sendEmail, profile?.email]);

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'send_email',
            label: 'Send Email',
            onClick: onSendEmailDoctor
          },
          {
            key: 'send_notification',
            label: 'Send Notification',
            onClick: () => toggleModal('sendNotification', true, { id })
          }
        ]
      }}
      disabled={!allowToEdit}
    >
      <Button
        size="large"
        type="primary"
        loading={isSending}
        icon={<SendOutlined />}
        style={{ width: 140 }}
        disabled={!allowToEdit}
      >
        Send
      </Button>
    </Dropdown>
  );
};

export default Send;
