import { Flex, Image, Typography } from 'antd';
import React, { FC } from 'react';
import {
  useOrganizationsDispatchContext,
  useOrganizationsStateContext
} from 'providers/Organizations';

import { getFileUrl } from 'utils/file';
import { SelectItem, StyledSelect } from './styled';

interface IProps {
  isDisabled: boolean;
  onClickDisabled(): void;
}

const { Text } = Typography;

const ParentOrganizationSelect: FC<IProps> = ({
  isDisabled,
  onClickDisabled
}) => {
  const { parentSelected, results } = useOrganizationsStateContext();
  const { onChangeParent } = useOrganizationsDispatchContext();

  const options = results.map(item => ({
    label: (
      <Flex align="center" gap={8}>
        {!!item.icon && (
          <Image
            width={24}
            height={24}
            src={getFileUrl(item.icon)}
            preview={false}
          />
        )}
        <Text style={{ fontWeight: 600, fontSize: 16 }}>{item.label}</Text>
      </Flex>
    ),
    value: item.value
  }));

  if (isDisabled) {
    const selected = options.find(item => item.value === parentSelected);

    if (!selected) {
      return null;
    }

    return <SelectItem onClick={onClickDisabled}>{selected?.label}</SelectItem>;
  }

  if (options.length === 1) {
    return <SelectItem>{options?.[0]?.label}</SelectItem>;
  }

  return (
    <StyledSelect
      style={{ width: 200 }}
      placeholder="Select organization"
      options={options}
      value={parentSelected}
      onChange={e => onChangeParent(e as string)}
    />
  );
};

export default ParentOrganizationSelect;
