import {
  createContext,
  useReducer,
  useContext,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_ORGANIZATIONS,
  GET_SUB_ORGANIZATIONS
} from 'gql/organizations/queries';
import { OrganizationsAdmin as OrganizationsType } from 'gql/organizations/__generated__/OrganizationsAdmin';
import { ADMIN_USER_ID } from 'constants/permissions';
import { useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/selectors';
import { useSearchParams } from 'react-router-dom';

import reducer from './reducers';
import {
  State,
  Dispatch,
  DispatchContext,
  IOrganizationsProps,
  ActionTypes
} from './types';
import { parentOrgStorage, subOrgStorage } from 'utils/storage';
import { Loading } from 'components/ui';

import { OrganizationByParentIdQuery } from '@/generated/graphql';

const QUERIES_TO_IGNORE = ['OrganizationByParentId', 'OrganizationsAdmin'];

const initialState: State = {
  parentSelected: parentOrgStorage.get() || '',
  subSelected: '',
  selected: '',
  results: [],
  subResults: []
};

const OrganizationsStateContext = createContext<State | undefined>(undefined);
const OrganizationsDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

const OrganizationsProvider: FC<IOrganizationsProps> = ({ children }) => {
  const data = useAppSelector(selectUserData());
  const [isLoading, setLoading] = useState(true);

  const isAdmin = `${data?.id}` === ADMIN_USER_ID;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState
  });

  const [getSubOrganization] = useLazyQuery<OrganizationByParentIdQuery>(
    GET_SUB_ORGANIZATIONS,
    {
      onCompleted(data) {
        dispatch({
          type: ActionTypes.SET_SUB_ORGANIZATIONS,
          data:
            data.organizationByParentId?.data?.map(item => ({
              label: item.name,
              value: item.id,
              icon: item.image
            })) || []
        });
        setLoading(false);
      }
    }
  );

  useQuery<OrganizationsType>(GET_ORGANIZATIONS, {
    fetchPolicy: 'no-cache',
    skip: !data,
    onCompleted(data) {
      const results = [
        ...(isAdmin
          ? [
              {
                label: 'All',
                value: 'all'
              }
            ]
          : []),
        ...(data?.organizationsAdmin.data || []).map(item => ({
          label: item.name,
          icon: item.image,
          value: item.id
        }))
      ];

      dispatch({ type: ActionTypes.SET_ORGANIZATIONS, data: results });
      let parentOrganizationValue;

      const storedValue = parentOrgStorage.get();

      const existing = results.find(item => item.value === storedValue);

      if (existing) {
        parentOrganizationValue = existing.value;
      } else {
        const firstValue = results?.[0]?.value;

        if (firstValue) {
          parentOrgStorage.set(firstValue);
          parentOrganizationValue = firstValue;
        }
      }

      if (parentOrganizationValue) {
        dispatch({
          type: ActionTypes.SELECT_PARENT_ORGANIZATION,
          data: parentOrganizationValue
        });
      }

      if (parentOrganizationValue === 'all') {
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (state.parentSelected && state.parentSelected !== 'all') {
      getSubOrganization({
        variables: {
          query: {
            parent_id: state.parentSelected
          }
        }
      });
    } else {
      setLoading(false);
    }
  }, [getSubOrganization, state.parentSelected]);

  useEffect(() => {
    const selected = state.parentSelected || state.subSelected;

    if (selected) {
      dispatch({
        type: ActionTypes.SELECT_PARENT_ORGANIZATION,
        data: selected
      });
    }
  }, [state.parentSelected, state.subSelected]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <OrganizationsStateContext.Provider value={state}>
      <OrganizationsDispatchContext.Provider value={dispatch}>
        {children}
      </OrganizationsDispatchContext.Provider>
    </OrganizationsStateContext.Provider>
  );
};

const useOrganizationsStateContext = (): State => {
  const context = useContext(OrganizationsStateContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useOrganizationsStateContext must be used within a OrganizationsProvider'
    );
  }

  return context;
};

const useOrganizationsDispatchContext = (): DispatchContext => {
  const dispatch = useContext(OrganizationsDispatchContext);
  const client = useApolloClient();
  const [searchParams, setSearchParams] = useSearchParams();

  if (typeof dispatch === 'undefined') {
    throw new Error(
      'useOrganizationsDispatchContext must be used within a OrganizationsProvider'
    );
  }

  const resetData = useCallback(() => {
    const queries = client.getObservableQueries('active');
    const actives = Array.from(queries.values());
    const names = actives
      .filter(
        query => query.queryName && !QUERIES_TO_IGNORE.includes(query.queryName)
      )
      .map(item => item.queryName || '');

    client.refetchQueries({ include: names });
  }, [client]);

  const onChangeParent: DispatchContext['onChangeParent'] = useCallback(
    value => {
      parentOrgStorage.set(value);
      subOrgStorage.remove();
      dispatch({ type: ActionTypes.SELECT_PARENT_ORGANIZATION, data: value });

      if (searchParams.get('page')) {
        searchParams.delete('page');
        setSearchParams(searchParams);
      }

      resetData();
    },
    [dispatch, resetData, searchParams, setSearchParams]
  );

  const onChangeSub: DispatchContext['onChangeSub'] = useCallback(
    value => {
      subOrgStorage.set(value);
      dispatch({ type: ActionTypes.SELECT_SUB_ORGANIZATION, data: value });

      if (searchParams.get('page')) {
        searchParams.delete('page');
        setSearchParams(searchParams);
      }

      resetData();
    },
    [dispatch, resetData, searchParams, setSearchParams]
  );

  return { onChangeSub, onChangeParent };
};

export default OrganizationsProvider;
export { useOrganizationsDispatchContext, useOrganizationsStateContext };
