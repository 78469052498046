import { useQuery } from '@apollo/client';
import { GET_SUBSCRIPTION_FEATURES } from 'gql/subscription/queries';

import { GetSubscriptionFeaturesQuery } from '@/generated/graphql';

const useSubscriptionFeatures = () => {
  const features = useQuery<GetSubscriptionFeaturesQuery>(
    GET_SUBSCRIPTION_FEATURES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        query: {
          pagination: {
            page: 0,
            limit: 100
          }
        }
      }
    }
  );

  return features.data?.getSubscriptionFeatures?.data.results || [];
};

export default useSubscriptionFeatures;
