import { FC } from 'react';
import { Col, Form, Input } from 'antd';

import { FormValues } from './_types';
import { DEFAULT_VALUES } from './_constants';

interface IProps {
  name: string;
  label: string;
  length: number;
}

const Field: FC<IProps> = ({ name, label, length }) => {
  const form = Form.useFormInstance<FormValues>();

  const baseName = Object.keys(DEFAULT_VALUES)
    .find(key => name.startsWith(key.replace(/Value.*$/, '')))
    ?.replace(/Value.*$/, '');

  const switchState = Form.useWatch(baseName, form);

  return (
    <Col xs={length > 2 ? 12 : 24}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          { required: true },
          {
            pattern: /^\d+(\.\d{1,2})?$/,
            message: 'Enter a valid value (e.g., 10 or 10.50)'
          }
        ]}
      >
        <Input disabled={!switchState} />
      </Form.Item>
    </Col>
  );
};

export default Field;
