import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Descriptions,
  Timeline,
  Tag,
  Empty,
  Image,
  Space
} from 'antd';
import { useTranslation } from 'react-i18next';
import { GENDERS_VALUES } from 'constants/users';
import dayjs from 'dayjs';
import { DATE_FORMAT, FULL_DATE_FORMAT } from 'constants/dates';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_MEDICAL_TREATMENT,
  GET_MEDICAL_TREATMENT_BY_ID
} from 'gql/appointments/queries';
import {
  MEDICAL_TREATMENT_COLOR,
  MEDICAL_TREATMENT_LABEL,
  MEDICAL_TREATMENT_STATUS,
  MedicalTreatmentValues
} from 'constants/appointments';
import { useUIDispatchContext } from 'providers/UI';
import { UPDATE_TREATMENT } from 'gql/appointments/mutations';
import client from 'apolloClient';
import { WAY_OF_COMMUNICATION } from 'constants/medical_treatment';
import { parseJson } from '@medicusunion/front-utils';
import { useOrganizationsStateContext } from 'providers/Organizations';

import { getFileUrl } from 'utils/file';
import { Loading, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';

import { GetTreatmentFormByIdQuery } from '@/generated/graphql';

const CALCULATION_LABELS = {
  documentTranslation: 'Document Translation',
  visa_assistance: 'Visa Assistance',
  airportTransfer: 'Airport Transfer',
  interpreterServices: 'Interpreter Services',
  fullClinicSupport: 'Full Clinic Support',
  organization: 'Country',
  vipService: 'Vip Service'
};

const MedicalTreatmentDetails: FC = () => {
  const { toggleModal } = useUIDispatchContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const { results: organizations } = useOrganizationsStateContext();

  const { data, loading } = useQuery<GetTreatmentFormByIdQuery>(
    GET_MEDICAL_TREATMENT_BY_ID,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id: params.id
      }
    }
  );

  const [updateMedicalTreatment] = useMutation(UPDATE_TREATMENT, {
    onCompleted() {
      toggleModal('updateStatus', false);
      client.refetchQueries({
        include: [GET_MEDICAL_TREATMENT, GET_MEDICAL_TREATMENT_BY_ID]
      });
    },
    onError: err => showErrorMessage(err)
  });

  const medicalTreatmentId = data?.getTreatmentFormById?.data;
  const files = medicalTreatmentId?.files || [];

  const calculations = Object.entries(
    parseJson(medicalTreatmentId?.calculations) || {}
  );

  const renderCalculationValue = (key: string, value: unknown) => {
    switch (key) {
      case 'airportTransfer':
      case 'documentTranslation':
      case 'fullClinicSupport':
      case 'vipService':
      case 'visaAssistance':
        return value ? 'YES' : 'NO';

      case 'interpreterServices':
        switch (value) {
          case 'interpreterServicesValuesUp2hours':
            return 'up 2 hours';
          case 'interpreterServicesValuesFrom2to4hours':
            return '2-4 hours';
          case 'interpreterServicesValuesFrom4to6hours':
            return '4-6 hours';
          case 'interpreterServicesValuesFullDay':
            return 'Full day';
          default:
            return '-';
        }

      case 'organization': {
        if (value) {
          const organization = organizations.find(item => item.value === value);

          if (organization) {
            return (
              <Space size={8}>
                <Image
                  src={getFileUrl(organization.icon)}
                  width={24}
                  height={24}
                  preview={false}
                />
                {organization.label}
              </Space>
            );
          }
        }

        return '-';
      }

      default:
        return '-';
    }
  };

  if (!medicalTreatmentId || loading) {
    return <Loading />;
  }

  return (
    <PageWrapper
      back
      onClickBack={() => navigate(-1)}
      title={`Medical Treatment: #${params.id}`}
      extra={[
        <Button
          key="change-status-button"
          type="primary"
          onClick={() =>
            toggleModal('updateStatus', true, {
              id: params.id,
              currentStatus: `${medicalTreatmentId.status}`,
              statusList: MEDICAL_TREATMENT_LABEL,
              onSubmit: values => {
                updateMedicalTreatment({
                  variables: {
                    id: params.id,
                    input: {
                      note: values.note,
                      status: Number(values.status)
                    }
                  }
                });
              }
            })
          }
        >
          Change status
        </Button>
      ]}
    >
      <Card title="Personal Information" style={{ marginBottom: 24 }}>
        <Descriptions labelStyle={{ fontWeight: 'bold' }}>
          <Descriptions.Item label="User Name">
            {medicalTreatmentId.first_name} {medicalTreatmentId.last_name}
          </Descriptions.Item>
          <Descriptions.Item label="Phone number">
            <a href={`tel:${medicalTreatmentId.phone}`}>
              {medicalTreatmentId.phone}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            <a href={`mailto:${medicalTreatmentId.email}`}>
              {medicalTreatmentId.email}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Gender">
            {!!medicalTreatmentId.gender && !medicalTreatmentId.calculations
              ? t(
                  `appointments_details.${
                    GENDERS_VALUES[
                      medicalTreatmentId.gender as keyof typeof GENDERS_VALUES
                    ]
                  }`
                )
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Date of birth">
            {medicalTreatmentId.date_of_birth
              ? dayjs(medicalTreatmentId.date_of_birth).format(DATE_FORMAT)
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Created at">
            {dayjs(medicalTreatmentId.created_at).format(FULL_DATE_FORMAT)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card
        title="Medical Treatment Details – Calculation Options"
        style={{ marginBottom: 24 }}
      >
        {calculations.length ? (
          <Descriptions labelStyle={{ fontWeight: 'bold' }}>
            {calculations.map(([key, value]) => (
              <Descriptions.Item
                label={
                  CALCULATION_LABELS[key as keyof typeof CALCULATION_LABELS] ||
                  key
                }
                key={key}
              >
                <>{renderCalculationValue(key, value)}</>
              </Descriptions.Item>
            ))}
          </Descriptions>
        ) : (
          <Empty description="There are no calculation options for this request" />
        )}
      </Card>
      {!medicalTreatmentId.calculations && (
        <Card
          title="Medical Treatment Details – Deprecated"
          style={{ marginBottom: 24 }}
        >
          <Descriptions
            layout="vertical"
            column={1}
            labelStyle={{ fontWeight: 'bold' }}
          >
            <Descriptions.Item label="Preferred Way of Communication">
              {medicalTreatmentId.way_of_communication
                ? WAY_OF_COMMUNICATION.find(
                    item =>
                      item.value === medicalTreatmentId.way_of_communication
                  )?.label
                : '_'}
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {medicalTreatmentId.description
                ? medicalTreatmentId.description
                : '_'}
            </Descriptions.Item>
            <Descriptions.Item label="Files" span={2}>
              {files.length ? (
                <ul>
                  {files.map(item => {
                    return (
                      <li key={`file-${item.filename}-${item.id}`}>
                        <a
                          href={getFileUrl(item.filename)}
                          download={item}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.filename}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                '-'
              )}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
      <Card title="History">
        <Timeline
          items={[
            ...(Array.isArray(medicalTreatmentId.history)
              ? medicalTreatmentId.history.map(item => ({
                  children: (
                    <>
                      <p key={`history-${item.id}`}>
                        Status changed to{' '}
                        <Tag
                          color={
                            MEDICAL_TREATMENT_COLOR[
                              item.status as MedicalTreatmentValues
                            ]
                          }
                        >
                          {
                            MEDICAL_TREATMENT_LABEL[
                              item.status as MedicalTreatmentValues
                            ]
                          }
                        </Tag>
                        on{' '}
                        <b>{dayjs(item.created_at).format(FULL_DATE_FORMAT)}</b>
                      </p>
                      <p style={{ fontWeight: 500 }}>{item.note}</p>
                    </>
                  )
                }))
              : []),
            {
              children: (
                <p>
                  Request created with a status{' '}
                  <Tag
                    color={
                      MEDICAL_TREATMENT_COLOR[MEDICAL_TREATMENT_STATUS.new]
                    }
                  >
                    {MEDICAL_TREATMENT_LABEL[MEDICAL_TREATMENT_STATUS.new]}
                  </Tag>
                  on{' '}
                  <b>
                    {dayjs(medicalTreatmentId.created_at).format(
                      FULL_DATE_FORMAT
                    )}
                  </b>
                </p>
              )
            }
          ]}
        />
      </Card>
    </PageWrapper>
  );
};

export default MedicalTreatmentDetails;
