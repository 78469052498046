import {
  Form,
  Image,
  Input,
  Modal,
  Space,
  Typography,
  Upload,
  Collapse,
  Select,
  Row,
  Col
} from 'antd';
import { FC, memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { CREATE_SPECIALTY, UPDATE_SPECIALTY } from 'gql/specialties/mutations';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { GET_SPECIALTIES, GET_SPECIALTY_BY_ID } from 'gql/specialties/queries';
import { CreateSpecialty as CreateSpecialtyType } from 'gql/specialties/__generated__/CreateSpecialty';
import { Specialty } from 'gql/specialties/__generated__/Specialty';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { Specialties } from 'gql/specialties/__generated__/Specialties';
import { useFileUpload } from 'hooks';
import { useAuthStateContext } from 'providers/Auth';

import { showErrorMessage } from 'utils/showErrorMessage';
import { Loading } from 'components/ui';
import { getFileUrl } from 'utils/file';

const { TextArea } = Input;
const { Text } = Typography;

const itemValues = {
  name: '',
  description: '',
  short_description: ''
};

type ValuesType = typeof itemValues;
type AdditionalTypes = {
  visible: boolean;
  symptom: boolean;
  parents?: string[];
};
type BodyType = Record<string, ValuesType>;

const { Panel } = Collapse;

interface IProps {
  refetch(): Promise<ApolloQueryResult<Specialties>>;
}

const SpecialtyModal: FC<IProps> = ({ refetch }) => {
  // translations
  const { t } = useTranslation();
  // form
  const [form] = Form.useForm();
  // ui
  const { toggleModal } = useUIDispatchContext();
  const { specialtyModal } = useModalStateContext();

  const specialtyId = specialtyModal.params?.id;

  // state
  const [icon, setIcon] = useState('');
  const [image, setImage] = useState('');
  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  // AWS
  const { upload } = useFileUpload();

  const { languages } = useAuthStateContext();

  const closeModal = () => {
    toggleModal('specialtyModal', false);
    form.resetFields();
    setIcon('');
    setImage('');
    setErrorKeys([]);
  };

  const specialties = useQuery<Specialties>(GET_SPECIALTIES, {
    fetchPolicy: 'no-cache',
    skip: !specialtyModal.visible,
    variables: {
      query: {
        lang: 'en',
        symptom: true
      }
    }
  });

  const { loading: isFetching } = useQuery<Specialty>(GET_SPECIALTY_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: specialtyId
    },
    skip: !specialtyId,
    onCompleted(data) {
      if (data?.specialty?.data) {
        const { visible, symptom, image, icon, translates, parents } =
          data.specialty.data;

        const values = translates.reduce((acc: BodyType, val) => {
          return {
            ...acc,
            [val.lang]: {
              name: val.name,
              description: val.description || '',
              short_description: val.short_description || ''
            }
          };
        }, {});

        form.setFieldsValue({
          visible,
          image,
          symptom,
          parents: parents?.map(PId => PId.parent_id),
          icon,
          ...values
        });
        setIcon(data?.specialty?.data.icon || '');
        setImage(data?.specialty?.data.image || '');
      }
    }
  });

  const [createSpecialty, { loading: isCreating }] =
    useMutation<CreateSpecialtyType>(CREATE_SPECIALTY, {
      onCompleted() {
        refetch();
        closeModal();
      },
      onError: err => showErrorMessage(err)
    });

  const [updateSpecialty, { loading: isUpdating }] = useMutation(
    UPDATE_SPECIALTY,
    {
      onCompleted() {
        refetch();
        closeModal();
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onFinish = useCallback(
    ({ visible, parents, symptom, ...values }: AdditionalTypes & BodyType) => {
      setErrorKeys([]);
      const input = {
        image,
        icon,
        visible: visible || false,
        symptom: symptom || false,
        parents: parents || null,
        ...values
      };

      if (specialtyId) {
        return updateSpecialty({
          variables: {
            id: specialtyId,
            input
          }
        });
      }

      createSpecialty({
        variables: {
          input
        }
      });
    },
    [image, icon, specialtyId, createSpecialty, updateSpecialty]
  );

  const onFinishError = (
    errorInfo: ValidateErrorEntity<
      {
        visible: boolean;
      } & BodyType
    >
  ) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  const results = specialties.data?.specialties?.data.filter(
    item => item.id !== specialtyId
  );

  const isSymptom = Form.useWatch('symptom', form);

  const hasAllLanguagesAccess = languages.every(item => item.allowEdit);

  return (
    <Modal
      title={
        <Space>{t(specialtyId ? 'common.edit' : 'specialty.add_new')} </Space>
      }
      onCancel={closeModal}
      open={specialtyModal.visible}
      width={600}
      onOk={form.submit}
      confirmLoading={isCreating || isUpdating}
      destroyOnClose
    >
      {isFetching ? (
        <Loading />
      ) : (
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishError}
          layout="vertical"
          size="large"
        >
          <div>
            <Space>
              <Form.Item label={t('specialty.icon')}>
                <Upload
                  customRequest={e =>
                    upload(e.file as File).then(val => setIcon(val || ''))
                  }
                  multiple={false}
                  maxCount={1}
                  showUploadList={false}
                  disabled={!hasAllLanguagesAccess}
                >
                  <Image
                    src={getFileUrl(icon)}
                    width={100}
                    height={100}
                    preview={false}
                  />
                </Upload>
              </Form.Item>
              <Form.Item label={t('specialty.image')}>
                <Upload
                  customRequest={e =>
                    upload(e.file as File).then(val => setImage(val || ''))
                  }
                  multiple={false}
                  maxCount={1}
                  showUploadList={false}
                  disabled={!hasAllLanguagesAccess}
                >
                  <Image
                    src={getFileUrl(image)}
                    width={100}
                    height={100}
                    preview={false}
                  />
                </Upload>
              </Form.Item>
            </Space>
          </div>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item name="visible" label="Visibility">
                <Select
                  options={[
                    {
                      label: 'Visible',
                      value: true
                    },
                    {
                      label: 'Hidden',
                      value: false
                    }
                  ]}
                  disabled={!hasAllLanguagesAccess}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="symptom"
                label="Type"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    {
                      label: 'Specialty',
                      value: false
                    },
                    {
                      label: 'Symptom',
                      value: true
                    }
                  ]}
                  disabled={!hasAllLanguagesAccess}
                />
              </Form.Item>
            </Col>
          </Row>
          {!isSymptom && (
            <Form.Item
              name="parents"
              label="Symptoms"
              rules={[{ required: true }]}
            >
              <Select
                mode="multiple"
                options={results?.map(item => ({
                  label: item.name,
                  value: item.id
                }))}
                allowClear
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={!hasAllLanguagesAccess}
              />
            </Form.Item>
          )}
          <Collapse
            accordion
            defaultActiveKey={languages[0].locale}
            items={languages.map(item => {
              const hasError = errorKeys.includes(item.locale);

              return {
                key: item.key,
                label: item.label,
                forceRender: true,
                children: (
                  <Panel
                    header={
                      <Text
                        style={{
                          color: `var(--${
                            hasError ? 'error-color' : 'text-color'
                          })`
                        }}
                      >
                        {item.label}
                        {hasError ? ' (error)' : ''}
                      </Text>
                    }
                    key={item.locale}
                    forceRender
                  >
                    <Form.Item
                      name={[item.locale, 'name']}
                      label={t('specialty.name')}
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!item.allowEdit} />
                    </Form.Item>
                    <Form.Item
                      name={[item.locale, 'short_description']}
                      label={t('specialty.short_description')}
                      rules={[
                        { required: false },
                        {
                          max: 165,
                          message: 'Value should be less than 50 character'
                        }
                      ]}
                    >
                      <Input disabled={!item.allowEdit} />
                    </Form.Item>
                    <Form.Item
                      name={[item.locale, 'description']}
                      label={t('specialty.description')}
                      rules={[{ required: false }]}
                    >
                      <TextArea disabled={!item.allowEdit} />
                    </Form.Item>
                  </Panel>
                )
              };
            })}
          />
        </Form>
      )}
    </Modal>
  );
};

export default memo(SpecialtyModal);
