import { LANGUAGES } from 'constants/languages';

const BULGARIA_LANGUAGES = ['bg'];

export const getLanguageAccess = (roleName?: string) => {
  if (roleName?.includes('Bulgaria')) {
    return LANGUAGES.map(item => ({
      ...item,
      allowEdit: BULGARIA_LANGUAGES.includes(item.locale)
    }));
  }

  return LANGUAGES;
};
