import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Me_me_data } from 'gql/profile/__generated__/Me';

import { tokenStorage } from 'utils/storage';

type UserType = {
  user: Me_me_data | null;
  isLoggedIn: boolean;
  isLoading: boolean;
};

const isLoggedIn = !!tokenStorage.get();

const initialState: UserType = {
  isLoggedIn,
  user: null,
  isLoading: true
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Me_me_data | null>) => {
      state.user = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  }
});

export const { setIsLoggedIn, setUserLoading, setUser } = userSlice.actions;
export default userSlice.reducer;
