import { FC, useEffect, useMemo, useState } from 'react';
import { Layout, Menu, Flex, Typography, Result, Button } from 'antd';
import { useQuery } from '@apollo/client';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { DoctorById } from 'gql/doctors/__generated__/DoctorById';
import { GET_ATTRIBUTE_GROUPS } from 'gql/attributes/queries';
import { FetchAttributeGroups } from 'gql/attributes/__generated__/FetchAttributeGroups';
import { useAuthStateContext } from 'providers/Auth';

import { PageWrapper } from 'components/ui';
import {
  ProfileCompleteness,
  ChangeDoctorStatus,
  DoctorInfo,
  PlansModal,
  Send
} from './components';
import { SIDEBAR_OPTIONS } from './_constants';
import { StyledLayout } from './styled';
import { DynamicPage } from './pages';
import { LanguageSwitcher } from 'components/shared';

const { Content, Sider } = Layout;
const { Title } = Typography;

const Profile: FC = () => {
  const { id, page } = useParams();
  const navigate = useNavigate();

  const [specialtyError, setSpecialtyError] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const lang = useMemo(() => searchParams.get('lang'), [searchParams]);

  const { languages } = useAuthStateContext();

  const allowToEdit = languages.find(item => item.locale === lang)?.allowEdit;

  const { data: profileData, loading: isFetchingProfile } =
    useQuery<DoctorById>(GET_DOCTOR_PROFILE, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        id,
        lang
      },
      onError(err) {
        if (err.message.includes('Specialty.name.')) {
          setSpecialtyError(true);
        }
      }
    });

  const { data: attributeGroupsData, loading: isFetchingAttributeGroups } =
    useQuery<FetchAttributeGroups>(GET_ATTRIBUTE_GROUPS, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    });

  const profile = profileData?.doctorById?.data;
  const attributeGroups = attributeGroupsData?.fetchAttributeGroups?.data;

  useEffect(() => {
    if (!lang) {
      searchParams.set('lang', 'en');
      setSearchParams(searchParams);
    }
  }, [lang, searchParams, setSearchParams]);

  const resetToEnglish = () => {
    searchParams.set('lang', 'en');
    setSearchParams(searchParams);
    setSpecialtyError(false);
  };

  const sidebarMenu = useMemo(
    () => [
      ...SIDEBAR_OPTIONS.map(item => ({
        key: item.slug,
        label: (
          <Link to={`/access/doctors/${id}/${item.slug}?lang=${lang}`}>
            {item.name}
          </Link>
        )
      })),
      ...(attributeGroups?.map(item => ({
        key: `${item?.slug}`,
        label: (
          <Link to={`/access/doctors/${id}/${item?.slug}?lang=${lang}`}>
            {item?.name}
          </Link>
        )
      })) || [])
    ],
    [attributeGroups, id, lang]
  );

  const activePage = useMemo(() => {
    const staticPage = SIDEBAR_OPTIONS.find(item => item.slug === page);

    if (staticPage) {
      return staticPage;
    }

    return attributeGroups?.find(item => item?.slug === page);
  }, [attributeGroups, page]);

  const attributeGroupId = useMemo(() => {
    if (activePage && 'component' in activePage) {
      return '';
    }

    return profile?.groups.find(item => item?.group_id === activePage?.id)?.id;
  }, [activePage, profile?.groups]);

  const PageComponent =
    activePage && 'component' in activePage
      ? activePage.component
      : DynamicPage;

  if (specialtyError) {
    return (
      <Flex style={{ height: 'calc(100vh - 48px)' }} justify="center" vertical>
        <Result
          status="error"
          title="Complete Specialties First"
          subTitle="Please fill in all specialties in the selected language before continuing"
          extra={
            <Flex gap={16} justify="center">
              <Button type="default" onClick={resetToEnglish}>
                Cancel
              </Button>
              <Button type="primary" onClick={() => navigate('/specialties')}>
                Go to Specialties
              </Button>
            </Flex>
          }
        />
      </Flex>
    );
  }

  return (
    <PageWrapper
      back
      loading={isFetchingProfile || isFetchingAttributeGroups || !lang}
      onClickBack={() => navigate('/access/doctors')}
      breadcrumbs={{
        items: [
          {
            title: 'Users'
          },
          {
            title: <Link to="/access/doctors">Doctors</Link>
          },
          {
            title: profile ? `${profile.first_name} ${profile.last_name}` : ''
          }
        ]
      }}
    >
      <StyledLayout>
        <Sider width={340}>
          <DoctorInfo id={`${id}`} lang={`${lang}`} allowToEdit={allowToEdit} />
          <Flex style={{ marginBottom: 40 }} gap={12}>
            <Send id={`${id}`} lang={`${lang}`} allowToEdit={allowToEdit} />
            <LanguageSwitcher lang={`${lang}`} />
          </Flex>
          <Menu selectedKeys={[`${page}`]} items={sidebarMenu} />
          <ProfileCompleteness id={`${id}`} lang={`${lang}`} />
        </Sider>
        <Content>
          <Flex
            style={{ marginBottom: 32 }}
            justify="space-between"
            align="center"
          >
            <Title level={3} style={{ margin: 0 }}>
              {activePage?.name}
            </Title>
            <ChangeDoctorStatus id={`${id}`} lang={`${lang}`} />
          </Flex>
          {PageComponent && (
            <PageComponent
              id={`${id}`}
              pageId={`${activePage?.id}`}
              lang={`${lang}`}
              doctorGroupId={attributeGroupId}
              allowToEdit={allowToEdit}
            />
          )}
        </Content>
      </StyledLayout>
      <PlansModal />
    </PageWrapper>
  );
};

export default Profile;
