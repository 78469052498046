import { useMutation, useQuery } from '@apollo/client';
import { App, Button, Flex, Table } from 'antd';
import { GetDicom } from 'gql/sessions/__generated__/GetDicom';
import { GET_DICOM } from 'gql/sessions/queries';
import { FC, memo, useCallback, useState } from 'react';
import {
  DeleteOutlined,
  EyeOutlined,
  ShareAltOutlined
} from '@ant-design/icons';
import { DELETE_DICOM_LINK } from 'gql/sessions/mutations';
import { useTranslation } from 'react-i18next';

import { showErrorMessage } from 'utils/showErrorMessage';
import { getFileUrl } from 'utils/file';
import useCopyText from 'hooks/useCopyText';

const PAGE_LIMIT = 10;

const DicomList: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const { copyText } = useCopyText();

  const { modal, message } = App.useApp();
  const { data, loading, refetch } = useQuery<GetDicom>(GET_DICOM, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        pagination: {
          page,
          limit: PAGE_LIMIT
        }
      }
    }
  });

  const [deleteDicomLink] = useMutation(DELETE_DICOM_LINK, {
    onCompleted() {
      refetch();
    },
    onError: err => showErrorMessage(err)
  });

  const onClickDelete = (id: string) => {
    modal.confirm({
      title: 'Warning',
      content: <p>Are you sure?</p>,
      onOk: () => {
        deleteDicomLink({
          variables: {
            id
          }
        });
      }
    });
  };

  const onClickShare = useCallback(
    (link: string) => {
      copyText(link);
      message.success(t('messages.copied'));
    },
    [copyText, message, t]
  );

  const results = data?.getDicom.data.results || [];
  const total = data?.getDicom.data.total || 0;

  return (
    <Table
      dataSource={results}
      columns={[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Title',
          dataIndex: 'name',
          key: 'name',
          render(value) {
            return value || '-';
          }
        },
        {
          title: 'File',
          dataIndex: 'file',
          key: 'file',
          render(value) {
            return value ? (
              <a
                download={value}
                target="_blank"
                rel="noreferrer"
                href={getFileUrl(value)}
              >
                {value}
              </a>
            ) : (
              '-'
            );
          }
        },
        {
          title: '',
          dataIndex: 'id',
          key: 'actions',
          width: '5%',
          align: 'right',
          render: (id, record) => {
            const link = `${record.redirect_url}?sessionId=${record.session_id}`;

            return (
              <Flex align="center" gap={16}>
                <Button
                  icon={<DeleteOutlined />}
                  type="text"
                  onClick={() => onClickDelete(id)}
                  danger
                >
                  {t('table.delete')}
                </Button>
                <Button
                  icon={<ShareAltOutlined />}
                  onClick={() => onClickShare(link)}
                  // onClick={() => setIsOpenModal(true)}
                >
                  Share
                </Button>
                <a target="_blank" href={link} rel="noreferrer">
                  <Button icon={<EyeOutlined />} type="primary" ghost>
                    {t('sessions.view_dicom')}
                  </Button>
                </a>
              </Flex>
            );
          }
        }
      ]}
      loading={loading}
      rowKey="id"
      pagination={{
        total,
        pageSize: PAGE_LIMIT,
        onChange: p => setPage(p - 1),
        showSizeChanger: false
      }}
    />
  );
};

export default memo(DicomList);
