import { FC, useEffect, useState } from 'react';
import { Flex, Avatar, Typography, Upload, Spin } from 'antd';
import { useMutation } from '@apollo/client';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { EditOutlined } from '@ant-design/icons';
import { useFileUpload, usePermission } from 'hooks';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';
import { UPDATE_PROFILE } from 'gql/doctors/mutations';
import client from 'apolloClient';

import { StyledDoctor } from './styled';
import { getFileUrl } from 'utils/file';
import { Private } from 'components/shared';
import { showErrorMessage } from 'utils/showErrorMessage';
import useDoctorProfileCache from 'hooks/useDoctorProfileCache';

interface IProps {
  id: string;
  lang: string;
  allowToEdit?: boolean;
}

const DoctorInfo: FC<IProps> = ({ id, lang, allowToEdit }) => {
  const { checkUserPermission } = usePermission();
  const { profile, validateProfile } = useDoctorProfileCache({ id, lang });
  const [avatar, setAvatar] = useState('');

  const { upload, loading } = useFileUpload({
    loading: true
  });

  const [updateProfile, { loading: isUpdating }] = useMutation(UPDATE_PROFILE, {
    onCompleted() {
      setAvatar('');
      client.refetchQueries({ include: [GET_DOCTOR_PROFILE] });
    },
    onError: err => showErrorMessage(err)
  });

  useEffect(() => {
    if (avatar && avatar !== profile?.avatar) {
      updateProfile({
        variables: {
          input: {
            cover: profile?.cover || '',
            first_name: profile?.first_name || '',
            last_name: profile?.last_name || '',
            languages: profile?.languages,
            gender: profile?.gender || '',
            phone: profile?.phone || '',
            address: profile?.address || '',
            timezone: profile?.timezone || '',
            about: profile?.about || '',
            specialties: profile?.specialties?.map(item => +item.id) || [],
            avatar,
            user_id: id || profile?.id,
            lang
          }
        }
      });
    }
  }, [avatar, id, lang, profile, updateProfile]);

  const hasProfileUpdatePermission = checkUserPermission(
    PERMISSION_ROUTES.doctors,
    PERMISSION_ACTIONS.update
  );

  return (
    <StyledDoctor>
      <Flex align="center" gap={18}>
        <Upload
          accept="image/*"
          disabled={
            !hasProfileUpdatePermission || loading || isUpdating || !allowToEdit
          }
          customRequest={e =>
            validateProfile(() =>
              upload(e.file as File).then(val => setAvatar(val || ''))
            )
          }
          showUploadList={false}
          className="avatar-container"
        >
          {loading ? (
            <Flex
              style={{ width: 60, height: 60 }}
              justify="center"
              align="center"
            >
              <Spin />
            </Flex>
          ) : (
            <>
              <Avatar src={getFileUrl(avatar || profile?.avatar)} size={60} />
              <Private
                route={PERMISSION_ROUTES.doctors}
                action={PERMISSION_ACTIONS.update}
              >
                <span className="avatar-edit-icon">
                  <EditOutlined />
                </span>
              </Private>
            </>
          )}
        </Upload>
        <div>
          <Typography.Title level={5}>
            {profile?.first_name} {profile?.last_name}
          </Typography.Title>
          <a className="doctor-email" href={`mailto:${profile?.email}`}>
            {profile?.email}
          </a>
        </div>
      </Flex>
    </StyledDoctor>
  );
};

export default DoctorInfo;
