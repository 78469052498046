import { App, Button, Flex } from 'antd';
import { FC, memo, useRef, useState } from 'react';
import { getFirstErrorMessage } from '@medicusunion/front-utils';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';

import { Private } from 'components/shared';
import TagsSelect, {
  TagsSelectRef
} from 'pages/DoctorProfile/components/TagsSelect';

interface IProps {
  lang: string;
  allowToEdit?: boolean;
}

const AdvancedSettings: FC<IProps> = ({ lang, allowToEdit }) => {
  const { message, modal } = App.useApp();
  const [isLoading, setLoading] = useState(false);
  const tagsRef = useRef<TagsSelectRef>(null);

  const onSave = async () => {
    try {
      setLoading(true);
      await tagsRef.current?.save();
      message.success('Successfully saved');
    } catch (err) {
      modal.error({
        title: 'Error',
        content: <p>{getFirstErrorMessage(err)}</p>,
        closable: true
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <TagsSelect ref={tagsRef} language={lang} disabled={!allowToEdit} />
      <Private
        route={PERMISSION_ROUTES.doctors}
        action={PERMISSION_ACTIONS.update}
      >
        <Flex justify="flex-end" style={{ paddingTop: 24 }}>
          <Button
            type="primary"
            size="large"
            onClick={onSave}
            loading={isLoading}
            disabled={!allowToEdit}
          >
            Save
          </Button>
        </Flex>
      </Private>
    </div>
  );
};

export default memo(AdvancedSettings);
