export type FormValues = {
  treatmentPlanning: boolean;
  treatmentPlanningValue: number;
  documentTranslation: boolean;
  documentTranslationValue: number;
  visaAssistance: boolean;
  visaAssistanceValue: number;
  airportTransfer: boolean;
  airportTransferValue: number;
  interpreterServices: boolean;
  interpreterServicesValuesUp2hours: number;
  interpreterServicesValuesFrom2to4hours: number;
  interpreterServicesValuesFrom4to6hours: number;
  interpreterServicesValuesFullDay: number;
  fullClinicSupport: boolean;
  fullClinicSupportValue: number;
  vat: boolean;
  vatValue: number;
};

export enum FieldTypes {
  switch,
  field
}
