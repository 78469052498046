export type LocaleType = 'en' | 'ru' | 'de' | 'bg' | 'ar';

export type LanguagesType = {
  label: string;
  key: string;
  locale: LocaleType;
  allowEdit: boolean;
};

export const LANGUAGES: LanguagesType[] = [
  {
    label: 'English',
    key: 'english',
    locale: 'en',
    allowEdit: true
  },
  {
    label: 'Russian',
    key: 'russian',
    locale: 'ru',
    allowEdit: true
  },
  {
    label: 'German',
    key: 'german',
    locale: 'de',
    allowEdit: true
  },
  {
    label: 'Arabic',
    key: 'arabic',
    locale: 'ar',
    allowEdit: true
  },
  {
    label: 'Bulgarian',
    key: 'bulgarian',
    locale: 'bg',
    allowEdit: true
  }
];
