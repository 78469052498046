import styled from 'styled-components';

export const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  .checkbox-form-item {
    .ant-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      .ant-col {
        width: auto;
        padding: 0;
      }
      label {
        white-space: nowrap;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
`;
