import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { parseJson } from '@medicusunion/front-utils';
import { App, Button, Col, Flex, Form, Input, Row, Select, Space } from 'antd';
import { GENDERS } from 'constants/users';
import {
  ATTACH_DOCTOR_TO_SERVICES,
  UPDATE_PROFILE
} from 'gql/doctors/mutations';
import { GET_DOCTOR_PROFILE } from 'gql/doctors/queries';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_LANGUAGES } from 'gql/translationPlans/queries';
import { Languages } from 'gql/translationPlans/__generated__/Languages';
import { usePermission } from 'hooks';
import { PERMISSION_ROUTES, PERMISSION_ACTIONS } from 'constants/permissions';
import { GET_SPECIALTIES } from 'gql/specialties/queries';
import { Specialties } from 'gql/specialties/__generated__/Specialties';
import { RTL_LANGUAGES } from 'constants/translations';

import type { ProfileForm, LanguageType } from '../../_types';
import { showErrorMessage } from 'utils/showErrorMessage';
import { OrganizationResult, Private } from 'components/shared';
import { LanguageRow } from './styled';
import useDoctorProfileCache from 'hooks/useDoctorProfileCache';
import { ServicesSelect } from 'pages/DoctorProfile/components';

interface IProps {
  id: string;
  lang: string;
  allowToEdit?: boolean;
}

const PersonalInformation: FC<IProps> = ({ id, lang, allowToEdit }) => {
  const { t } = useTranslation();
  const { checkUserPermission } = usePermission();
  const client = useApolloClient();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { profile } = useDoctorProfileCache({ id, lang });

  const { data: specialtiesData } = useQuery<Specialties>(GET_SPECIALTIES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        lang
      }
    }
  });

  const { data: languagesData } = useQuery<Languages>(GET_LANGUAGES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        page: 0,
        limit: 200
      }
    }
  });

  const [updateProfile, { loading: isUpdating }] = useMutation(UPDATE_PROFILE, {
    onError: err => showErrorMessage(err)
  });

  const [attachServices, { loading: isAttaching }] = useMutation(
    ATTACH_DOCTOR_TO_SERVICES,
    {
      onCompleted() {
        message.success('Successfully saved');
        client.refetchQueries({ include: [GET_DOCTOR_PROFILE] });
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onFinish = (values: ProfileForm) => {
    const services = values.services;
    delete values.email;
    delete values.services;

    const languages = (values.languages as LanguageType[])
      ?.map((item, index) => {
        return {
          ...item,
          default: index === 0
        };
      })
      .filter(item => item.value);

    updateProfile({
      variables: {
        input: {
          ...values,
          specialties: values?.specialties?.map(item => +item) || [],
          avatar: profile?.avatar,
          cover: profile?.cover,
          languages: languages?.length ? languages : null,
          about: profile?.about || '',
          user_id: id || profile?.id,
          lang
        }
      }
    }).finally(() => {
      attachServices({
        variables: {
          input: {
            doctor_id: id,
            service_ids: services || []
          }
        }
      });
    });
  };

  const hasProfileUpdatePermission = checkUserPermission(
    PERMISSION_ROUTES.doctors,
    PERMISSION_ACTIONS.update
  );

  const languages = parseJson(profile?.languages);

  return (
    <OrganizationResult>
      <Form
        form={form}
        layout="vertical"
        size="large"
        disabled={!hasProfileUpdatePermission}
        initialValues={{
          first_name: profile?.first_name || '',
          last_name: profile?.last_name || '',
          email: profile?.email || '',
          languages: languages?.length ? languages : [{}],
          gender: profile?.gender || '',
          phone: profile?.phone || '',
          address: profile?.address || '',
          timezone: profile?.timezone || '',
          specialties: profile?.specialties?.map(item => item.id) || [],
          services: profile?.service?.map(item => item.id) || []
        }}
        onFinish={onFinish}
      >
        <Row gutter={[16, 0]}>
          <Col md={12}>
            <Form.Item
              name="first_name"
              label={t('doctor_profile.name')}
              rules={[{ required: true }]}
            >
              <Input
                dir={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'}
                maxLength={255}
                disabled={!allowToEdit}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name="last_name"
              label={t('doctor_profile.surname')}
              rules={[{ required: true }]}
            >
              <Input
                dir={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'}
                maxLength={255}
                disabled={!allowToEdit}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name="gender"
              label={t('doctor_profile.gender')}
              rules={[{ required: true }]}
            >
              <Select
                direction={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'}
                options={GENDERS}
                disabled={!allowToEdit}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name="timezone"
              label={t('doctor_profile.timezone')}
              rules={[{ required: true }]}
            >
              <Select
                direction={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                options={Intl.supportedValuesOf('timeZone').map(item => ({
                  label: item,
                  value: item
                }))}
                size="large"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.label as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={!allowToEdit}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              name="phone"
              label={t('doctor_profile.phone')}
              rules={[{ required: true }]}
            >
              <Input
                dir={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'}
                maxLength={255}
                disabled={!allowToEdit}
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item name="email" label={t('doctor_profile.email')}>
              <Input
                dir={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'}
                maxLength={255}
                disabled
              />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item
              name="address"
              label={t('doctor_profile.location')}
              rules={[{ required: true }]}
            >
              <Input
                dir={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'}
                maxLength={255}
                disabled={!allowToEdit}
              />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item
              label="Specialties"
              name="specialties"
              rules={[{ required: true }]}
            >
              <Select
                direction={RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.label.includes(input) || false
                }
                filterSort={(optionA, optionB) =>
                  optionA.label
                    .toLowerCase()
                    .localeCompare(optionB?.label.toLowerCase())
                }
                options={
                  specialtiesData?.specialties?.data.map(item => ({
                    label: item.name,
                    value: item.id
                  })) || []
                }
                mode="multiple"
                disabled={!allowToEdit}
              />
            </Form.Item>
          </Col>
          <Col md={24}>
            <ServicesSelect lang={lang} disabled={!allowToEdit} />
          </Col>
          <Col md={24}>
            <Form.List name="languages">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <LanguageRow key={`language-${field.key}`}>
                      <Space size={4} style={{ width: '100%' }} align="end">
                        <Form.Item
                          name={field.name}
                          label={index === 0 && t('doctor_profile.languages')}
                          style={{ margin: 0 }}
                          rules={[{ required: true }]}
                        >
                          <Select
                            direction={
                              RTL_LANGUAGES.includes(lang) ? 'rtl' : 'ltr'
                            }
                            options={
                              languagesData?.languages?.data.results.map(
                                item => ({
                                  value: item.code,
                                  label: item.name
                                })
                              ) || []
                            }
                            placeholder={t('profile.select_language')}
                            optionFilterProp="label"
                            labelInValue
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label as unknown as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            disabled={!allowToEdit}
                          />
                        </Form.Item>
                        <Button onClick={add} disabled={!allowToEdit}>
                          <PlusOutlined />
                        </Button>
                        {index > 0 && (
                          <Button
                            onClick={() => remove(index)}
                            disabled={!allowToEdit}
                          >
                            <MinusOutlined />
                          </Button>
                        )}
                      </Space>
                    </LanguageRow>
                  ))}
                </>
              )}
            </Form.List>
          </Col>
          <Col span={24}>
            <Flex justify="end" gap={12}>
              <Private
                route={PERMISSION_ROUTES.doctors}
                action={PERMISSION_ACTIONS.update}
              >
                <Button
                  size="large"
                  type="primary"
                  onClick={form.submit}
                  loading={isUpdating || isAttaching}
                  disabled={!allowToEdit}
                >
                  Save
                </Button>
              </Private>
            </Flex>
          </Col>
        </Row>
      </Form>
    </OrganizationResult>
  );
};

export default memo(PersonalInformation);
