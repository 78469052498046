import React, { FC, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Collapse, Form, Modal, Typography, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { CMS_FIELDS } from 'constants/cms';
import { GET_CONFIGS } from 'gql/configs/queries';
import { GetConfig } from 'gql/configs/__generated__/GetConfig';
import { useFileUpload } from 'hooks';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { CREATE_CONFIG } from 'gql/configs/mutations';
import client from 'apolloClient';
import { LANGUAGES } from 'constants/languages';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { CollapseProps } from 'antd/lib';

import { showErrorMessage } from 'utils/showErrorMessage';
import { Loading } from 'components/ui';
import { getFileUrl } from 'utils/file';

const { Title, Text } = Typography;
const { Panel } = Collapse;

type UploadedFiles = {
  [locale: string]: {
    [key in string]?: File | string;
  };
};

type ValuesType = { [key: string]: string };

type BodyType = Record<string, ValuesType>;

const EditStaticElements: FC = () => {
  const { t } = useTranslation();
  const { staticElements } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  const [form] = Form.useForm();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>({});

  const [errorKeys, setErrorKeys] = useState<string[]>([]);

  const { upload } = useFileUpload({
    handleResult: true
  });

  const onClose = () => {
    toggleModal('staticElements', false);
    setErrorKeys([]);
  };

  const { data, loading } = useQuery<GetConfig>(GET_CONFIGS, {
    variables: {
      query: {
        type: staticElements.params?.type
      }
    }
  });

  const [createConfig, { loading: createLoading }] = useMutation(
    CREATE_CONFIG,
    {
      onCompleted() {
        client.refetchQueries({ include: [GET_CONFIGS] });
        onClose();
      },
      onError: err => showErrorMessage(err)
    }
  );

  const onFinishError = (
    errorInfo: ValidateErrorEntity<
      {
        visible: boolean;
      } & BodyType
    >
  ) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  const result = data?.getConfig?.data.results?.[0]?.body;

  useEffect(() => {
    if (result) {
      form.setFieldsValue(result);
      setUploadedFiles(result);
    }
  }, [form, result]);

  const banners = CMS_FIELDS.find(
    item => item.config_type === staticElements.params?.type
  )?.banners;

  const onUploadFile = async (
    e: UploadRequestOption,
    fieldName: string,
    locale: string
  ) => {
    try {
      const res = await upload(e.file as File);
      e.onSuccess?.(res);
      setUploadedFiles(prev => {
        const existingFilesForLocale = prev[locale] || {};
        const updatedFilesForLocale = {
          ...existingFilesForLocale,
          [fieldName]: res
        };

        return { ...prev, [locale]: updatedFilesForLocale };
      });
    } catch (err) {
      e.onError?.(err as ProgressEvent<EventTarget>);
    }
  };

  const handleSubmit = useCallback(
    (values: { [key: string]: string }) => {
      const formData = { ...values, ...uploadedFiles };
      createConfig({
        variables: {
          input: {
            body: JSON.stringify(formData),
            type: staticElements.params?.type
          }
        }
      });
    },
    [createConfig, staticElements.params?.type, uploadedFiles]
  );

  const items: CollapseProps['items'] = LANGUAGES.map(item => {
    const hasError = errorKeys.includes(item.locale);

    return {
      key: item.key,
      label: item.label,
      forceRender: true,
      children: (
        <Panel
          header={
            <Text
              style={{
                color: `var(--${hasError ? 'error-color' : 'text-color'})`
              }}
            >
              {item.label}
              {hasError ? ' (error)' : ''}
            </Text>
          }
          key={item.locale}
          forceRender
        >
          {banners?.map((banner, index) => {
            return (
              <Form.Item key={`${item}-${index}`} name={[item.locale, banner]}>
                <div>
                  <Title level={3}>{t(`cms.${banner}`)}</Title>
                  <Upload.Dragger
                    name="files"
                    maxCount={1}
                    multiple={false}
                    customRequest={e => onUploadFile(e, banner, item.locale)}
                    fileList={[
                      {
                        uid: `${banner}-${index}`,
                        name: uploadedFiles?.[item.locale]?.[banner] as string,
                        url: getFileUrl(
                          uploadedFiles?.[item.locale]?.[banner] as string
                        )
                      }
                    ]}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </Upload.Dragger>
                </div>
              </Form.Item>
            );
          })}
        </Panel>
      )
    };
  });

  if (loading) {
    return <Loading type="static" />;
  }

  return (
    <Modal
      open={staticElements?.visible}
      onCancel={onClose}
      okButtonProps={{ htmlType: 'submit', loading: createLoading }}
      onOk={form.submit}
      okText="Save"
    >
      <Form
        form={form}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        onFinish={handleSubmit}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        onFinishFailed={onFinishError}
        initialValues={result}
      >
        <Collapse
          accordion
          defaultActiveKey={LANGUAGES[0].locale}
          style={{ marginTop: 24 }}
          items={items}
        />
      </Form>
    </Modal>
  );
};

export default EditStaticElements;
