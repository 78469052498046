import { useTranslation } from 'react-i18next';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { Button, Table, Typography } from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SUBSCRIPTION_FEATURE_ITEMS } from 'gql/subscription/queries';
import { useCallback, useMemo, useState } from 'react';
import { ColumnProps } from 'antd/es/table';
import { UNIT_OPTIONS_VALUES } from 'constants/subscriptions';
import { DELETE_SUBSCRIPTION_FEATURE_ITEM } from 'gql/subscription/mutations';
import { useUIDispatchContext } from 'providers/UI';

import { Private } from 'components/shared';
import { ActionMenu, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { useSubscriptionFeatures } from '../hooks';

import { GetFeatureItemsQuery } from '@/generated/graphql';

const PAGE_LIMIT = 10;

type ColumnType = NonNullable<
  NonNullable<GetFeatureItemsQuery['getFeatureItems']>['data']
>['results'][0];

const { Text } = Typography;

const SubscriptionFeatures = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { toggleModal } = useUIDispatchContext();
  const { data, loading, refetch } = useQuery<GetFeatureItemsQuery>(
    GET_SUBSCRIPTION_FEATURE_ITEMS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        query: {
          pagination: {
            page,
            limit: PAGE_LIMIT
          }
        }
      }
    }
  );

  const results = data?.getFeatureItems?.data.results;
  const total = data?.getFeatureItems?.data.total;

  const features = useSubscriptionFeatures();

  const [deleteSubscriptionFeature] = useMutation(
    DELETE_SUBSCRIPTION_FEATURE_ITEM,
    {
      onCompleted() {
        refetch();
      },
      onError(err) {
        showErrorMessage(err);
      }
    }
  );

  const onDeletePlan = useCallback(
    (id: string) => {
      toggleModal('confirm', true, {
        title: t('subscription.delete_subscription_feature'),
        onConfirm: () => deleteSubscriptionFeature({ variables: { id } })
      });
    },
    [deleteSubscriptionFeature, t, toggleModal]
  );

  const columns: ColumnProps<ColumnType>[] = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (id: string) => `#${id}`
      },
      {
        title: t('subscription.feature_name'),
        dataIndex: 'subscription_feature_id',
        key: 'subscription_feature_id',
        render: (id: string) => {
          const feature = features?.find(item => item.id === id);

          return (
            <span dangerouslySetInnerHTML={{ __html: feature?.name || '' }} />
          );
        }
      },
      {
        title: t('subscription.measurement_unit'),
        dataIndex: 'unit',
        key: 'unit',
        render: (value: keyof typeof UNIT_OPTIONS_VALUES) => {
          return <Text>{t(`subscription.${UNIT_OPTIONS_VALUES[value]}`)}</Text>;
        }
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        render: (_, { id }) => (
          <ActionMenu
            data={[
              {
                title: t('table.edit'),
                permission: {
                  route: PERMISSION_ROUTES.faq,
                  action: PERMISSION_ACTIONS.update
                },
                action() {
                  navigate(`/subscriptions/features/${id}`);
                },
                menuProps: {
                  style: {
                    color: 'var(--primary)'
                  }
                }
              },
              {
                title: t('table.delete'),
                permission: {
                  route: PERMISSION_ROUTES.faq,
                  action: PERMISSION_ACTIONS.delete
                },
                action() {
                  onDeletePlan(id);
                },
                menuProps: {
                  style: {
                    color: 'var(--danger-color)'
                  }
                }
              }
            ]}
            placement="bottomRight"
            icon={<EllipsisOutlined />}
          />
        )
      }
    ],
    [features, navigate, onDeletePlan, t]
  );

  return (
    <PageWrapper
      color="white"
      title={t('subscription.subscription_features')}
      extra={[
        <Private
          key="add-blog-button"
          route={PERMISSION_ROUTES.blog}
          action={PERMISSION_ACTIONS.create}
        >
          <Button
            type="primary"
            onClick={() => navigate('/subscriptions/features/details')}
          >
            <PlusOutlined />
            {t('subscription.create_feature')}
          </Button>
        </Private>
      ]}
    >
      <Table
        rowKey="id"
        columns={columns}
        dataSource={results}
        loading={loading}
        pagination={{
          total: total || 0,
          current: page + 1,
          onChange: p => {
            setPage(p - 1);
          },
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  );
};

export default SubscriptionFeatures;
