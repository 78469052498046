import { gql } from '@apollo/client';

export const FEATURE_ITEM_OPTIONS_FRAGMENT = gql`
  fragment FeatureItemOptionFragment on Option {
    id
    name
    limit
    type
    feature_item_option_id
    duration_type
  }
`;

export const FEATURE_ITEM_MINI_FRAGMENT = gql`
  fragment FeatureItemMiniFragment on FeatureItem {
    id
    subscription_feature_id
    unit
    value
  }
`;
